import ObjectsToCsv from "objects-to-csv";
import moment, { Moment } from "moment";
import {
    DailyAverage,
    DetailedNightData,
    PatientOverview,
    ValueAndTime,
} from "types/patientdata";
import { Organisation } from "types/user";
import { Filters } from "components/ValuesFilterFormControl/ValuesFilterFormControl.types";
import {
    dateToTimeAndDateString,
    dateToDayMonthYearString,
} from "./dateToString";

interface DailyAverageInCSVFormat {
    date: string;
    heart_rate_nonin_average?: number;
    spo2_average_average?: number;
    spo2_minimum_average?: number;
    pai_average?: number;
    hrv_average?: number;
    resting_heart_rate?: number;
    total_sleeptime_fitbit?: number;

    total_deep_sleep_fitbit?: number;
    percentage_deep_sleep_fitbit?: number;

    total_rem_sleep_fitbit?: number;
    percentage_rem_sleep_fitbit?: number;

    total_wake_sleep_fitbit?: number;
    percentage_wake_sleep_fitbit?: number;

    total_light_sleep_fitbit?: number;
    percentage_light_sleep_fitbit?: number;

    sleep_efficiency_fitbit?: number;
    total_activity_fitbit?: number;

    weight?: number;
    body_fat?: number;
    breathing_rate?: number;
}

interface ValueAndTimeInCSVFormat {
    type: keyof DetailedDataInCSVFormat;
    date: string;
    value: number;
}

interface DetailedDataInCSVFormat {
    heart_rate_nonin_values: ValueAndTimeInCSVFormat[];
    spo2_average_values: ValueAndTimeInCSVFormat[];
    spo2_minimum_values: ValueAndTimeInCSVFormat[];
    pai_values: ValueAndTimeInCSVFormat[];
    hrv_values: ValueAndTimeInCSVFormat[];
    sleep_stage_values?: ValueAndTimeInCSVFormat[];
    heart_rate_fitbit_values?: ValueAndTimeInCSVFormat[];
    activity_fitbit_values?: ValueAndTimeInCSVFormat[];
    cumulative_activity_fitbit_values?: ValueAndTimeInCSVFormat[];
}

const valueAndTimeToCSVFormat = (
    type: keyof DetailedDataInCSVFormat,
    filters: Filters,
    valueAndTimes?: ValueAndTime[]
): ValueAndTimeInCSVFormat[] => {
    if (!valueAndTimes || valueAndTimes.length === 0) {
        return [
            {
                type,
                date: "Geen data",
                value: 0,
            },
        ];
    }
    if (!filters.detailed[type]) {
        return [];
    }

    const valueAndTimeInCSVFormat: ValueAndTimeInCSVFormat[] =
        valueAndTimes.map((valueAndTime) => ({
            type,
            date: valueAndTime.x,
            value: valueAndTime.y,
        }));
    return valueAndTimeInCSVFormat;
};
export const createPatientListCSV = async (
    data: PatientOverview[],
    currentOrganisations: Organisation[]
) => {
    if (currentOrganisations.length === 0) {
        alert("Geen organisaties zijn geselecteerd!");
        return;
    }
    if (data.length === 0) {
        alert("Geen patientdata is aanwezig");
        return;
    }
    // TODO: figure out what format to use
    const dataReadyForCSV = data.map((patient) => ({
        id: patient.id,
        name: patient.name,
        organisation: patient.organisation,
    }));
    const csv = new ObjectsToCsv(dataReadyForCSV);
    let csvString = await csv.toString();
    if (!csvString.match(/^data:text\/csv/i)) {
        csvString = `data:text/csv;charset=utf-8,${csvString}`;
    }
    const encodedUri = encodeURI(csvString);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const csvName = currentOrganisations
        .map((organisation) => organisation.name)
        .join("_");
    link.setAttribute("download", `patientlist_${csvName}.csv`);
    document.body.appendChild(link);
    link.click();
};

export const createPatientDailyAveragesCSV = async (
    data: DailyAverage[] | null,
    patientName: string,
    startDate: Moment,
    endDate: Moment,
    filters: Filters = {
        detailed: {},
        average: {
            heart_rate_nonin_average: true,
            spo2_average_average: true,
            spo2_minimum_average: true,

            hrv_average: true,
            resting_heart_rate: true,
            sleep_efficiency_fitbit: true,
            total_sleeptime_fitbit: true,
            total_activity_fitbit: true,
            weight: true,
            breathing_rate: true,
            body_fat: true,
        },
        realTime: {},
    }
) => {
    if (!data || data.length === 0) {
        alert("Geen patientdata is aanwezig");
        return;
    }

    const getPercentage = (value?: number, total?: number) => {
        if (!value) return 0;
        if (!total) return 0;
        if (total === 0) return 0;
        return Math.round((value / total) * 100);
    };

    const dataReadyForCSV: DailyAverageInCSVFormat[] = data.map(
        (dailyAverage) => {
            const filteredDailyAverage: DailyAverageInCSVFormat = {
                date: dateToDayMonthYearString(
                    moment.utc(dailyAverage.date),
                    "YYYY-MM-DD"
                ),
                total_deep_sleep_fitbit:
                    dailyAverage.data.total_deep_sleep_fitbit,
                percentage_deep_sleep_fitbit: getPercentage(
                    dailyAverage.data.total_deep_sleep_fitbit,
                    dailyAverage.data.total_sleeptime_fitbit
                ),
                total_rem_sleep_fitbit:
                    dailyAverage.data.total_rem_sleep_fitbit,
                percentage_rem_sleep_fitbit: getPercentage(
                    dailyAverage.data.total_rem_sleep_fitbit,
                    dailyAverage.data.total_sleeptime_fitbit
                ),
                total_wake_sleep_fitbit:
                    dailyAverage.data.total_wake_sleep_fitbit,
                percentage_wake_sleep_fitbit: getPercentage(
                    dailyAverage.data.total_wake_sleep_fitbit,
                    dailyAverage.data.total_sleeptime_fitbit
                ),
                total_light_sleep_fitbit:
                    dailyAverage.data.total_light_sleep_fitbit,
                percentage_light_sleep_fitbit: getPercentage(
                    dailyAverage.data.total_light_sleep_fitbit,
                    dailyAverage.data.total_sleeptime_fitbit
                ),
            };
            if (filters.average.heart_rate_nonin_average)
                filteredDailyAverage.heart_rate_nonin_average =
                    dailyAverage.data.heart_rate_nonin_average;
            if (filters.average.spo2_average_average)
                filteredDailyAverage.spo2_average_average =
                    dailyAverage.data.spo2_average_average;
            if (filters.average.spo2_minimum_average)
                filteredDailyAverage.spo2_minimum_average =
                    dailyAverage.data.spo2_minimum_average;

            if (filters.average.hrv_average)
                filteredDailyAverage.hrv_average =
                    dailyAverage.data.hrv_average;

            if (filters.average.resting_heart_rate)
                filteredDailyAverage.resting_heart_rate =
                    dailyAverage.data.resting_heart_rate;
            if (filters.average.total_sleeptime_fitbit)
                filteredDailyAverage.total_sleeptime_fitbit =
                    dailyAverage.data.total_sleeptime_fitbit;
            if (filters.average.sleep_efficiency_fitbit)
                filteredDailyAverage.sleep_efficiency_fitbit =
                    dailyAverage.data.sleep_efficiency_fitbit;
            if (filters.average.total_activity_fitbit)
                filteredDailyAverage.total_activity_fitbit =
                    dailyAverage.data.total_activity_fitbit;
            if (filters.average.weight)
                filteredDailyAverage.weight = dailyAverage.data.weight;
            if (filters.average.body_fat)
                filteredDailyAverage.body_fat = dailyAverage.data.body_fat;
            if (filters.average.breathing_rate)
                filteredDailyAverage.breathing_rate =
                    dailyAverage.data.breathing_rate;

            return filteredDailyAverage;
        }
    );
    const csv = new ObjectsToCsv(dataReadyForCSV);
    let csvString = await csv.toString();
    if (!csvString.match(/^data:text\/csv/i)) {
        csvString = `data:text/csv;charset=utf-8,${csvString}`;
    }
    const encodedUri = encodeURI(csvString);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    const csvName = `${patientName}_${dateToDayMonthYearString(
        startDate,
        "YYYY-MM-DD"
    )}_${dateToDayMonthYearString(endDate, "YYYY-MM-DD")}`;
    link.setAttribute("download", `${csvName}.csv`);
    document.body.appendChild(link);
    link.click();
};

export const createPatientDetailedNightCSVFull = async (
    date: string,
    data: DetailedNightData | undefined,
    patientName: string,
    filters: Filters = {
        average: {},
        detailed: {
            heart_rate_nonin_values: true,
            spo2_average_values: true,
            pai_values: true,
            sleep_stage_values: true,

            heart_rate_fitbit_values: true,
            spo2_minimum_values: true,
            hrv_values: true,
            activity_fitbit_values: true,
            cumulative_activity_fitbit_values: true,
        },
        realTime: {},
    },
    startTime?: Moment,
    endTime?: Moment
) => {
    if (!data) {
        alert("Geen data is aanwezig");
        return;
    }
    const {
        heart_rate_fitbit_values,
        cumulative_activity_fitbit_values,
        heart_rate_nonin_values,
        activity_fitbit_values,
        spo2_minimum_values,
        spo2_average_values,
        sleep_stage_values,
        pai_values,
        hrv_values,
    } = data;
    const heartRateNoninData: ValueAndTimeInCSVFormat[] =
        valueAndTimeToCSVFormat(
            "heart_rate_nonin_values",
            filters,
            heart_rate_nonin_values.data
        );
    const cumulativeActivityData: ValueAndTimeInCSVFormat[] =
        valueAndTimeToCSVFormat(
            "cumulative_activity_fitbit_values",
            filters,
            cumulative_activity_fitbit_values?.data
        );
    const heartRateFitbitData: ValueAndTimeInCSVFormat[] =
        valueAndTimeToCSVFormat(
            "heart_rate_fitbit_values",
            filters,
            heart_rate_fitbit_values?.data
        );
    const activityData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "activity_fitbit_values",
        filters,
        activity_fitbit_values?.data
    );
    const sleepStageData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "sleep_stage_values",
        filters,
        sleep_stage_values?.data
    );
    const spO2AverageData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "spo2_average_values",
        filters,
        spo2_average_values.data
    );
    const spO2MinimumData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "spo2_minimum_values",
        filters,
        spo2_minimum_values.data
    );
    const hrvData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "hrv_values",
        filters,
        hrv_values.data
    );
    const paiData: ValueAndTimeInCSVFormat[] = valueAndTimeToCSVFormat(
        "pai_values",
        filters,
        pai_values.data
    );
    const dataReadyForCSV: ValueAndTimeInCSVFormat[] = [
        ...heartRateNoninData,
        ...spO2AverageData,
        ...spO2MinimumData,
        ...hrvData,
        ...paiData,
        ...heartRateFitbitData,
        ...cumulativeActivityData,
        ...activityData,
        ...sleepStageData,
    ].sort((a, b) => {
        if (a.date === "Geen Data" && b.date === "Geen data") return 0;
        if (a.date === "Geen data") return -1;
        if (b.date === "Geen data") return 1;
        return 0;
    });
    const csv = new ObjectsToCsv(dataReadyForCSV);
    let csvString = await csv.toString();
    if (!csvString.match(/^data:text\/csv/i)) {
        csvString = `data:text/csv;charset=utf-8,${csvString}`;
    }
    const encodedUri = encodeURI(csvString);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    let csvName;
    if (startTime && endTime) {
        csvName = `${patientName}_${startTime.format(
            "YYYY-MM-DD--HH-mm"
        )}_${endTime.format("YYYY-MM-DD--HH-mm")}`;
    } else {
        csvName = `${patientName}_${dateToDayMonthYearString(
            moment.utc(date),
            "YYYY-MM-DD"
        )}`;
    }

    link.setAttribute("download", `${csvName}.csv`);
    document.body.appendChild(link);
    link.click();
};
