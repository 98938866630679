import { LoginData, ManageableUser, Organisation, Role } from "types/user";
import { LoginRequest, LoginResponseData } from "api/types/Login";
import { Dispatch } from "redux";
import loginApiCall from "api/calls/login";
import getApiConfig from "store/apiconfig";
import localStorageManager from "data/localStorageManager";
import { History } from "history";
import store from "store/store";
import {
    getManageableUsers as getManageableUsersApiRequest,
    createUser as createUserApiRequest,
    deleteUser as deleteUserApiRequest,
    createOrganisation as createOrganisationApiRequest,
    getOrganisations as getOrganisationsApiRequest,
    archiveOrganisation as archiveOrganisationApiRequest,
    renameOrganisation as renameOrganisationApiRequest,
    enableFitbit as enableFitbitApiRequest,
    disableFitbit as disableFitbitApiRequest,
    resetPasswordComplete as resetPasswordCompleteApiRequest,
    resetPasswordStart as resetPasswordStartApiRequest,
    cronSettingsApiCall,
    removeWeightAlarm as removeWeightAlarmApiRequest,
} from "api/calls/user";
import {
    ArchiveOrganisationRequest,
    CreateOrganisationRequest,
    CreateUserRequest,
    DeleteUserRequest,
    GetManageableUsersResponse,
    RenameOrganisationRequest,
    ResetPasswordCompleteRequest,
    ResetPasswordStartRequest,
} from "api/types/User";
import { clearState } from "./user.reducer";
import UserActions from "./user.constants";
import {
    cronRefreshApiCall,
    cronSettingsUpdateApiCall,
} from "../../api/calls/patient";

export type Login = (loginData: LoginData) => void;
export const login =
    (loginData: LoginRequest) => async (dispatch: Dispatch) => {
        try {
            const response: LoginResponseData = await loginApiCall(
                getApiConfig(),
                loginData
            );
            let currentOrganisation: Organisation = {
                id: 0,
                name: "Temp",
                enable_fitbit: true,
            };
            if (loginData.email) {
                localStorageManager.setEmail(loginData.email);
            }
            if (response.jwt) {
                localStorageManager.setJWT(response.jwt);
            }
            if (Role[response.role]) {
                localStorageManager.setRole(response.role);
            } else {
                throw Error;
            }
            if (response.organisations && response.organisations[0]) {
                localStorageManager.setCurrentOrganisations([
                    response.organisations[0],
                ]);
                [currentOrganisation] = response.organisations;
            }
            if (response.organisation) {
                localStorageManager.setCurrentOrganisations([
                    response.organisation,
                ]);
                currentOrganisation = response.organisation;
            }
            if (response.organisations) {
                localStorageManager.setOrganisations(response.organisations);
            }
            return dispatch({
                type: UserActions.Login,
                payload: {
                    user: {
                        isLoggedIn: true,
                        email: loginData.email,
                        organisations: response.organisations && [
                            ...response.organisations,
                        ],
                        role: Role[response.role],
                        authToken: response.jwt,
                    },
                    currentOrganisations: [currentOrganisation],
                },
            });
        } catch (e) {
            console.error(e);
            return dispatch({
                type: UserActions.LoginError,
                payload: {
                    user: {
                        isLoggedIn: false,
                        authToken: undefined,
                        username: undefined,
                    },
                },
            });
        }
    };

export const setAllOrganisations = () => (dispatch: Dispatch) => {
    const allOrganisations = store.getState().user.user.organisations;
    localStorageManager.setCurrentOrganisations(allOrganisations);
    dispatch({
        type: UserActions.SetCurrentOrganisation,
        payload: {
            currentOrganisations: allOrganisations,
        },
    });
};
export const setNoOrganisations = () => (dispatch: Dispatch) => {
    localStorageManager.setCurrentOrganisations([]);
    dispatch({
        type: UserActions.SetCurrentOrganisation,
        payload: {
            currentOrganisations: [],
        },
    });
};
export const setCurrentOrganisation =
    (organisationID: number) => (dispatch: Dispatch) => {
        const newOrganisation = store
            .getState()
            .user.user.organisations.find(
                (organisation) => organisation.id === organisationID
            );
        if (!newOrganisation) {
            return;
        }
        const { currentOrganisations } = store.getState().user;
        if (
            currentOrganisations.find(
                (organisation) => organisation.id === newOrganisation.id
            )
        ) {
            const resultArray = currentOrganisations.filter(
                (organisation) => organisation.id !== newOrganisation.id
            );
            localStorageManager.setCurrentOrganisations(
                resultArray.length > 0 ? [resultArray[0]] : []
            );
            dispatch({
                type: UserActions.SetCurrentOrganisation,
                payload: {
                    currentOrganisations: resultArray,
                },
            });
        } else {
            const resultArray = [newOrganisation];
            localStorageManager.setCurrentOrganisations(resultArray);
            dispatch({
                type: UserActions.SetCurrentOrganisation,
                payload: {
                    currentOrganisations: resultArray,
                },
            });
        }
    };

export const getOrganisations = () => async (dispatch: Dispatch) => {
    try {
        const response = await getOrganisationsApiRequest(getApiConfig());
        localStorageManager.setOrganisations(response);
        return dispatch({
            type: UserActions.GetOrganisations,
            payload: {
                organisations: response,
            },
        });
    } catch (e) {
        console.error(e);
    }
    return null;
};

export type CreateOrganisation = (
    createOrganisationData: CreateOrganisationRequest
) => void;
export const createOrganisation =
    (createOrganisationData: CreateOrganisationRequest) =>
    async (dispatch: Dispatch) => {
        try {
            await createOrganisationApiRequest(
                getApiConfig(),
                createOrganisationData
            );
            await getOrganisations()(dispatch);
        } catch (e) {
            throw Error;
        }
        return null;
    };

export const getManageableUsers = () => async (dispatch: Dispatch) => {
    try {
        const { role } = store.getState().user.user;
        let organisations: Organisation[];
        if (role === "admin") {
            organisations = [...store.getState().user.user.organisations];
        } else {
            organisations = store.getState().user.currentOrganisations;
        }
        let manageableUsers: ManageableUser[] = [];
        for (const organisation of organisations) {
            const request = { organisation_id: organisation.id };
            const { email } = store.getState().user.user;
            const responseData: GetManageableUsersResponse =
                // eslint-disable-next-line no-await-in-loop
                await getManageableUsersApiRequest(getApiConfig(), request);
            if (responseData) {
                manageableUsers = [
                    ...manageableUsers,
                    ...responseData
                        .filter(
                            (manageableUser) => manageableUser.Email !== email
                        )
                        .map((manageableUser) => ({
                            username: manageableUser.UserName,
                            email: manageableUser.Email,
                            role: Role[manageableUser.role],
                            lastActive: manageableUser.LastActive,
                            organisation: organisation.name,
                        })),
                ];
            }
        }
        return dispatch({
            type: UserActions.GetManageableUsers,
            payload: {
                manageableUsers: [...manageableUsers],
            },
        });
    } catch (e) {
        console.error(e);
    }
    return null;
};

export type CreateUser = (createNewUserData: CreateUserRequest) => void;
export const createUser =
    (createNewUserData: CreateUserRequest) => async (dispatch: Dispatch) => {
        try {
            await createUserApiRequest(getApiConfig(), createNewUserData);
            await getManageableUsers()(dispatch);
        } catch (e) {
            console.error(e);
        }
    };

export type DeleteUser = (deleteUserData: DeleteUserRequest) => void;
export const deleteUser =
    (deleteUserData: DeleteUserRequest) => async (dispatch: Dispatch) => {
        try {
            await deleteUserApiRequest(getApiConfig(), deleteUserData);
            await getManageableUsers()(dispatch);
        } catch (e) {
            console.error(e);
        }
    };

export type ResetPasswordComplete = (
    resetPasswordCompleteData: ResetPasswordCompleteRequest
) => void;
export const resetPasswordComplete =
    (resetPasswordCompleteData: ResetPasswordCompleteRequest) => async () => {
        try {
            await resetPasswordCompleteApiRequest(
                getApiConfig(),
                resetPasswordCompleteData
            );
        } catch (e) {
            throw Error;
        }
    };

export type ResetPasswordStart = (
    resetPasswordStartData: ResetPasswordStartRequest
) => void;
export const resetPasswordStart =
    (resetPasswordStartData: ResetPasswordStartRequest) => async () => {
        try {
            await resetPasswordStartApiRequest(
                getApiConfig(),
                resetPasswordStartData
            );
        } catch (e) {
            throw Error;
        }
    };

export type Logout = (history: History) => void;
export const logout = (history: History) => (dispatch) => {
    localStorageManager.clear();
    dispatch({
        type: UserActions.Logout,
        payload: {
            state: clearState,
        },
    });
    history.push("/");
};

export type ArchiveOrganisation = (
    archiveOrganisationData: ArchiveOrganisationRequest
) => void;
export const archiveOrganisation: ArchiveOrganisation =
    (archiveOrganisationData) => async (dispatch: Dispatch) => {
        try {
            await archiveOrganisationApiRequest(
                getApiConfig(),
                archiveOrganisationData
            );
            const { currentOrganisations } = store.getState().user;
            if (
                currentOrganisations.find(
                    (organisation) =>
                        organisation.id ===
                        archiveOrganisationData.organisation_id
                )
            ) {
                setCurrentOrganisation(archiveOrganisationData.organisation_id)(
                    dispatch
                );
            }
            await getOrganisations()(dispatch);
        } catch (e) {
            throw Error;
        }
        return null;
    };

export type RenameOrganisation = (
    renameOrganisationData: RenameOrganisationRequest
) => void;
export const renameOrganisation: RenameOrganisation =
    (renameOrganisationData) => async (dispatch: Dispatch) => {
        try {
            await renameOrganisationApiRequest(
                getApiConfig(),
                renameOrganisationData
            );
            await getOrganisations()(dispatch);
        } catch (e) {
            throw Error;
        }
        return null;
    };

export type EnableFitbit = (id: number) => void;
export const enableFitbit: EnableFitbit =
    (id) => async (dispatch: Dispatch) => {
        try {
            await enableFitbitApiRequest(getApiConfig(), id);
            await getOrganisations()(dispatch);
        } catch (e) {
            throw Error;
        }
        return null;
    };

export type DisableFitbit = (id: number) => void;
export const disableFitbit: DisableFitbit =
    (id) => async (dispatch: Dispatch) => {
        try {
            await disableFitbitApiRequest(getApiConfig(), id);
            await getOrganisations()(dispatch);
        } catch (e) {
            throw Error;
        }
        return null;
    };

// Following function will be deprecated
export const cronSettings = async () => {
    try {
        await cronSettingsApiCall(getApiConfig());
    } catch (e) {
        console.error(e);
    }
};

export const cronRefresh = async () => {
    try {
        await cronRefreshApiCall(getApiConfig());
    } catch (e) {
        console.error(e);
    }
};

export const cronSettingsUpdate = async (user_name: string) => {
    try {
        await cronSettingsUpdateApiCall(getApiConfig(), { user_name });
    } catch (e) {
        throw Error(
            "Het aantal berichten naar de fitbit API is overschreden probeer het over een uur nog een keer"
        );
    }
};
// end of deprecated functions

export type RemoveWeightAlarm = (user_name: string) => void;
export const removeWeightAlarm = async (user_name: string) => {
    try {
        await removeWeightAlarmApiRequest(getApiConfig(), { user_name });
    } catch (e) {
        console.error(e);
    }
};
