import { PatientOverviewFullData } from "api/types/Organisation";

export interface ValueAndTime {
    x: any;
    y: number;
}

export const emptyGraphObject: GraphDataObject = {
    type: "",
    label: "",
    backgroundColor: "",
    pointBorderColor: "",
    borderColor: "",
    pointBorderWidth: 0,
    pointHoverRadius: 0,
    pointHoverBackgroundColor: "",
    pointHoverBorderColor: "",
    pointHoverBorderWidth: 0,
    pointRadius: 0,
    pointHitRadius: 0,
    tension: 0,
    yAxisID: `y-axis-0`,
    showLine: true,
    fill: false,
    data: [],
};

export interface GraphDataObject {
    type: string;
    label: string;
    backgroundColor: string;
    pointBorderColor: string;
    borderColor: string;
    pointBorderWidth: number;
    pointHoverRadius: number;
    pointHoverBackgroundColor: string;
    pointHoverBorderColor: string;
    pointHoverBorderWidth: number;
    pointRadius: number;
    pointHitRadius: number;
    tension: number;
    yAxisID: string;
    showLine: boolean;
    fill: boolean;
    cubisInterpolationMode?: string;
    spanGaps?: boolean;
    labels?: string[];
    data: ValueAndTime[];
}

export interface DetailedNightData {
    heart_rate_nonin_average?: number;
    spo2_average_average?: number;
    spo2_minimum_minimum?: number;
    heart_rate_nonin_values: GraphDataObject;
    spo2_average_values: GraphDataObject;
    spo2_minimum_values: GraphDataObject;
    pai_values: GraphDataObject;
    hrv_values: GraphDataObject;
    heart_rate_fitbit_average?: number | null;
    resting_heart_rate?: number | null;
    total_sleeptime_fitbit?: number | null;
    total_deep_sleep_fitbit?: number | null;
    total_rem_sleep_fitbit?: number | null;
    total_wake_sleep_fitbit?: number | null;
    total_light_sleep_fitbit?: number | null;
    sleep_efficiency_fitbit?: number | null;
    total_steps_fitbit?: number | null;
    sleep_stage_values?: GraphDataObject | null;
    heart_rate_fitbit_values?: GraphDataObject | null;
    activity_fitbit_values?: GraphDataObject | null;
    cumulative_activity_fitbit_values?: GraphDataObject | null;
}
export interface DipArrays {
    heart_rate_nonin_values: ValueAndTime[];
    spo2_average_values: ValueAndTime[];
    spo2_minimum_values: ValueAndTime[];
    pai_values: ValueAndTime[];
    hrv_values: ValueAndTime[];
    heart_rate_fitbit_values: ValueAndTime[];
}

export interface DetailedNight {
    date: string;
    data: DetailedNightData;
}

export interface DailyAverageGraphData {
    heart_rate_nonin_average: GraphDataObject;
    spo2_average_average: GraphDataObject;
    spo2_minimum_average: GraphDataObject;
    pai_average?: GraphDataObject;
    hrv_average: GraphDataObject;
    resting_heart_rate?: GraphDataObject;
    total_sleeptime_fitbit?: GraphDataObject;
    weight?: GraphDataObject;
    total_activity_fitbit?: GraphDataObject;
    temperature_difference?: GraphDataObject;
    breathing_rate?: GraphDataObject;
}

export interface DailyAverageNoninData {
    heart_rate_nonin_average?: number;
    spo2_average_average?: number;
    spo2_minimum_average?: number;
    pai_average?: number;
}

export interface DailyAverageFitbitBodyData {
    weight?: number;
    body_fat?: number;
}
export interface DailyAverageFitbitGeneralData {
    resting_heart_rate?: number;
    total_sleeptime_fitbit?: number;
    total_deep_sleep_fitbit?: number;
    total_rem_sleep_fitbit?: number;
    total_wake_sleep_fitbit?: number;
    total_light_sleep_fitbit?: number;
    sleep_efficiency_fitbit?: number;
    total_activity_fitbit?: number;
    temperature_difference?: number;
    breathing_rate?: number;
    hrv_average?: number;
}

export const getGeneralFitbitData = (
    data: DailyAverageFitbitData
): DailyAverageFitbitGeneralData => {
    const {
        resting_heart_rate,
        total_sleeptime_fitbit,
        total_deep_sleep_fitbit,
        total_rem_sleep_fitbit,
        total_wake_sleep_fitbit,
        total_light_sleep_fitbit,
        sleep_efficiency_fitbit,
        total_activity_fitbit,
        temperature_difference,
        breathing_rate,
        hrv_average,
    } = data;

    return {
        resting_heart_rate,
        total_sleeptime_fitbit,
        total_deep_sleep_fitbit,
        total_rem_sleep_fitbit,
        total_wake_sleep_fitbit,
        total_light_sleep_fitbit,
        sleep_efficiency_fitbit,
        total_activity_fitbit,
        temperature_difference,
        breathing_rate,
        hrv_average,
    };
};

export const getBodyFitbitData = (
    data: DailyAverageFitbitData
): DailyAverageFitbitBodyData => {
    const { body_fat, weight } = data;
    return {
        body_fat,
        weight,
    };
};

export interface DailyAverageFitbitData
    extends DailyAverageFitbitGeneralData,
        DailyAverageFitbitBodyData {}

export const getDailyAverageFitbitData = (
    data: DailyAverageData
): DailyAverageFitbitData => {
    const {
        resting_heart_rate,
        total_sleeptime_fitbit,
        total_deep_sleep_fitbit,
        total_rem_sleep_fitbit,
        total_wake_sleep_fitbit,
        total_light_sleep_fitbit,
        sleep_efficiency_fitbit,
        total_activity_fitbit,
        temperature_difference,
        breathing_rate,
        body_fat,
        weight,
        hrv_average,
    } = data;

    return {
        resting_heart_rate,
        total_sleeptime_fitbit,
        total_deep_sleep_fitbit,
        total_rem_sleep_fitbit,
        total_wake_sleep_fitbit,
        total_light_sleep_fitbit,
        sleep_efficiency_fitbit,
        total_activity_fitbit,
        temperature_difference,
        breathing_rate,
        body_fat,
        weight,
        hrv_average,
    };
};

export const getDailyAverageNoninData = (
    data: DailyAverageData
): DailyAverageNoninData => {
    const {
        heart_rate_nonin_average,
        spo2_average_average,
        spo2_minimum_average,
    } = data;

    return {
        heart_rate_nonin_average,
        spo2_average_average,
        spo2_minimum_average,
    };
};

export interface DailyAverageData
    extends DailyAverageFitbitData,
        DailyAverageNoninData {
    flags?: string[] | null;
}
export interface DailyAverage {
    date: string;
    data: DailyAverageData;
}

export interface Patient {
    dailyAverages: DailyAverage[] | null;
    dailyAveragesGraphData: DailyAverageGraphData | null;
    detailedNight: DetailedNight | null;
    realTimeNonin: RealTimeNoninData | null;
}

export interface PatientOverview extends PatientOverviewFullData {
    name: string;
    organisation?: string;
}

export interface RealTimeNoninData {
    heart_rate_nonin_values: GraphDataObject;
    spo2_values: GraphDataObject;
    pai_values: GraphDataObject;
    hrv_values: GraphDataObject;
}

export enum SleepStage {
    deep = 1,
    light = 3,
    rem = 5,
    awake = 7,
}
export const SleepStageCatalogue = {
    1: "deep",
    3: "light",
    5: "rem",
    6: "asleep",
    7: "awake",
};

export interface PatientSetting {
    setting_key: string;
    value: number;
}
