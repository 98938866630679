import React, { useState } from "react";
import moment from "moment";
import {
    Button,
    CircularProgress,
    Grid,
    Input,
    InputAdornment,
    MenuItem,
    Select,
    Slider,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    AccessTime,
    ChevronLeft,
    ChevronRight,
    ExpandMore,
} from "@material-ui/icons";
import { Scatter } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import {
    dateToDayMonthYearString,
    dateToTimeAndDateString,
    dateToTimeString,
} from "helpers/dateToString";
import { getDataSet } from "helpers/graphdata";
import { SleepStageCatalogue } from "types/patientdata";
import ValuesFilterFormControl from "../ValuesFilterFormControl/ValuesFilterFormControl";
import styles from "./PatientNightDetailsGraph.styles";
import {
    BottomFilterFormProps,
    DipLimitInputSteps,
    DipsDisplayProps,
    LimitNumberSelectorProps,
    LimitTypeSelectorProps,
    PatientNightDetailsGraphBodyProps,
    PatientNightDetailsGraphFooterProps,
    PatientNightDetailsGraphHeaderProps,
    TimeSelectorProps,
    TopFilterFormProps,
} from "./PatientNightDetailsGraph.types";

function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

export const PatientNightDetailsGraphHeader: React.FC<PatientNightDetailsGraphHeaderProps> =
    ({ patientName, times, changeNight, handleDateChange }) => {
        const classes = styles({});
        const { night } = useParams();
        const [sliderValues, setSliderValues] = useState<number | number[]>([
            0, 1440,
        ]);
        const [sliderCommittedValues, setSliderCommittedValues] = useState<
            number | number[]
        >([0, 1440]);
        const handleSliderChange = (event, value: number | number[]) => {
            setSliderValues(value);
        };

        const handleCommittedSliderchange = (
            event,
            value: number | number[]
        ) => {
            
            const previous = sliderCommittedValues;
            console.log(previous, value)
            setSliderCommittedValues(value);
            if (previous[0] !== value[0]) {
                handleDateChange(
                    moment.utc(`${night}T12:00:00`).subtract(1, "day").add(value[0], "minutes"),
                    "startTime"
                );
            } else {
                handleDateChange(
                    moment.utc(`${night}T12:00:00`).subtract(1, "day").add(value[1], "minutes"),
                    "endTime"
                );
            }
        };

        const valueLabelFormat = (value) =>
            dateToTimeAndDateString(
                moment.utc(`${night}T12:00:00`).subtract(1, "day").add(value, "minutes")
            );
        const previousDay = moment.utc(`${night}T12:00:00`).subtract(1, "day");
        const nextDay = moment.utc(`${night}T12:00:00`).add(1, "day");

        return (
            <Grid container direction="row">
                <Grid
                    container
                    item
                    className={classes.tableheader}
                    alignItems="center"
                    justify="space-between"
                >
                    <TimeSelector
                        times={times}
                        handleDateChange={handleDateChange}

                    />
                    <Grid item className={classes.tableheaderCell}>
                        <Typography className={classes.text}>
                            <b>{patientName} &#8226; </b>
                            {dateToDayMonthYearString(
                                times.startTime,
                                "YYYY-MM-DD"
                            )}{" "}
                            -{" "}
                            {dateToDayMonthYearString(
                                times.endTime,
                                "YYYY-MM-DD"
                            )}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.timefilterContainer}>
                        <Button
                            className={classes.headerButton}
                            startIcon={<ChevronLeft />}
                            onClick={() =>
                                changeNight(
                                    dateToDayMonthYearString(
                                        previousDay,
                                        "YYYY-MM-DD"
                                    )
                                )
                            }
                        >
                            Vorige dag
                        </Button>
                        <Button
                            className={classes.headerButton}
                            endIcon={<ChevronRight />}
                            onClick={() =>
                                changeNight(
                                    dateToDayMonthYearString(
                                        nextDay,
                                        "YYYY-MM-DD"
                                    )
                                )
                            }
                        >
                            Volgende dag
                        </Button>
                    </Grid>
                </Grid>
                <Grid item className={classes.sliderContainer}>
                    <Slider
                        min={0}
                        max={1440}
                        step={5}
                        value={sliderValues}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleCommittedSliderchange}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valueLabelFormat}
                        valueLabelDisplay="auto"
                        ValueLabelComponent={ValueLabelComponent}
                    />
                </Grid>
            </Grid>
        );
    };

export const PatientNightDetailsGraphBody: React.FC<PatientNightDetailsGraphBodyProps> =
    ({
        times,
        legendFilteredData,
        minHR,
        maxHR,
        minSpO2,
        maxSpO2,
        loadingData,
    }) => {
        const classes = styles({ empty: !legendFilteredData });

        if (loadingData) {
            return (
                <Grid
                    container
                    className={classes.graphContainer}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Grid container className={classes.graphContainer}>
                {legendFilteredData ? (
                    <Scatter
                        data={(canvas) =>
                            getDataSet(legendFilteredData, canvas)
                        }
                        options={{
                            maintainAspectRatio: false,
                            animation: {
                                duration: 0, // general animation time
                            },
                            hover: {
                                animationDuration: 0, // duration of animations when hovering an item
                            },
                            responsiveAnimationDuration: 0, // animation duration after a resize
                            responsive: true,
                            legend: {
                                display: false,
                            },

                            tooltips: {
                                callbacks: {
                                    label(tooltipItem, data) {
                                        let label = "undefined";
                                        const found = data.datasets.find(
                                            (dataset) =>
                                                dataset.data.find(
                                                    (point) =>
                                                        point.x ===
                                                            tooltipItem.xLabel &&
                                                        point.y ===
                                                            tooltipItem.yLabel
                                                )
                                        );
                                        if (found !== undefined) {
                                            label = found.label;
                                        }
                                        const y = tooltipItem.yLabel;
                                        const date = moment.utc(
                                            tooltipItem.xLabel
                                        );
                                        if (label === "Sleep stage")
                                            return `${label}\n(${dateToTimeString(
                                                date
                                            )}, ${SleepStageCatalogue[y]})`;
                                        return `${label}\n(${dateToTimeString(
                                            date
                                        )}, ${y})`;
                                    },
                                },
                            },
                            scales: {
                                xAxes: [
                                    {
                                        type: "time",
                                        stacked: false,

                                        time: {
                                            unit: "hour",
                                            min: times.startTime
                                                .toISOString()
                                                .slice(0, -1),
                                            max: times.endTime
                                                .toISOString()
                                                .slice(0, -1),
                                        },

                                        gridLines: {
                                            zeroLineColor: "transparent",
                                        },
                                    },
                                ],
                                yAxes: [
                                    {
                                        display: false,
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-0",
                                    },
                                    {
                                        stacked: true,
                                        position: "left",
                                        id: "y-axis-1",
                                        ticks: {
                                            min: minHR,
                                            max: maxHR,
                                        },
                                    },
                                    {
                                        display: false,
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-2",
                                    },
                                    {
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-3",
                                        ticks: {
                                            min: minSpO2,
                                            max: maxSpO2,
                                            stepSize: 1,
                                        },
                                    },
                                    {
                                        display: true,
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-5",
                                        ticks: {
                                            reverse: true,
                                            min: -4,
                                            max: 7,
                                            stepSize: 1,
                                            autoSkip: false,
                                            callback: (value) => {
                                                switch (value) {
                                                    case 7:
                                                        return "awake";
                                                    case 6:
                                                        return "asleep";
                                                    case 5:
                                                        return "rem";
                                                    case 3:
                                                        return "light";
                                                    case 1:
                                                        return "deep";
                                                    default:
                                                        return null;
                                                }
                                            },
                                        },
                                    },
                                    {
                                        display: false,
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-6",
                                    },
                                    {
                                        display: false,
                                        stacked: true,
                                        position: "right",
                                        id: "y-axis-7",
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                        }}
                    />
                ) : (
                    <Typography>Geen data aanwezig voor deze nacht</Typography>
                )}
            </Grid>
        );
    };

const LimitTypeSelector: React.FC<LimitTypeSelectorProps> = ({
    dipData,
    changeDipData,
}) => {
    const classes = styles({});
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Grid item className={classes.tableFooterCell}>
            <Grid container alignItems="center">
                <Grid item className={classes.limitTypeGrid}>
                    <Typography onClick={handleOpen}>
                        <b>Limiet op</b>
                    </Typography>
                </Grid>
                <Grid item>
                    <Select
                        value={dipData.type}
                        name="type"
                        onChange={changeDipData}
                        className={classes.limitTypeSelector}
                        IconComponent={() => (
                            <ExpandMore onClick={handleOpen} />
                        )}
                        disableUnderline
                        open={open}
                        onOpen={handleOpen}
                        onClose={handleClose}
                    >
                        <MenuItem value="spo2_average_values">
                            SpO2 gem
                        </MenuItem>
                        <MenuItem value="spo2_minimum_values">
                            SpO2 min
                        </MenuItem>
                        <MenuItem value="heart_rate_nonin_values">
                            Heart rate
                        </MenuItem>
                        <MenuItem value="pai_values">PAI</MenuItem>
                        <MenuItem value="hrv_values">HRV</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Grid>
    );
};

const DipsDisplay: React.FC<DipsDisplayProps> = ({ amountOfDips }) => {
    const classes = styles({});

    return (
        <Grid item className={classes.tableFooterCell}>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography>
                        <b>Dips onder limiet</b>
                    </Typography>
                </Grid>
                <Grid>
                    <Typography className={classes.dipsDisplay}>
                        {amountOfDips}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TopFilterForm: React.FC<TopFilterFormProps> = ({
    filters,
    handleFilterChange,
}) => {
    const classes = styles({});

    return (
        <Grid item className={classes.tableFooterCell}>
            <ValuesFilterFormControl
                value="heart_rate_nonin_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="spo2_average_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="pai_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="sleep_stage_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
        </Grid>
    );
};

const LimitNumberSelector: React.FC<LimitNumberSelectorProps> = ({
    dipData,
    handleInputChange,
    handleBlur,
}) => {
    const classes = styles({});
    return (
        <Grid item className={classes.tableFooterCell}>
            <Typography>
                <b>Limiet waarde</b>
                <Input
                    className={classes.limitNumberSelector}
                    value={dipData.limit}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    inputProps={{
                        step: DipLimitInputSteps[dipData.type].steps,
                        min: 0,
                        max: DipLimitInputSteps[dipData.type].max,
                        type: "number",
                        style: {
                            textAlign: "center",
                            width: "50px",
                        },
                    }}
                    disableUnderline
                />
            </Typography>
        </Grid>
    );
};

const TimeSelector: React.FC<TimeSelectorProps> = ({
    times,
    handleDateChange,
}) => {
    const { night } = useParams();
    const classes = styles({});
    const [isDatePickerOpen, setIsDatePickerOpen] = useState({
        startTime: false,
        endTime: false,
    });
    const changeStartTime = (date) => handleDateChange(date, "startTime");
    const changeEndTime = (date) => handleDateChange(date, "endTime");
    return (
        <Grid item className={classes.timefilterContainer}>
            <Typography>
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                >
                    <DateTimePicker
                        open={isDatePickerOpen.startTime}
                        onClose={() =>
                            setIsDatePickerOpen({
                                startTime: false,
                                endTime: false,
                            })
                        }
                        value={times.startTime}
                        clearable
                        onChange={changeStartTime}
                        minDate={moment.utc(`${night}T12:00:00`).subtract(1, "day")}
                        maxDate={times.endTime}
                        TextFieldComponent={() => (
                            <TextField
                                className={classes.datePickerTextField}
                                onClick={() =>
                                    setIsDatePickerOpen({
                                        startTime: true,
                                        endTime: false,
                                    })
                                }
                                value={dateToTimeAndDateString(times.startTime)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccessTime />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                    type: "button",
                                }}
                            />
                        )}
                    />
                    <DateTimePicker
                        open={isDatePickerOpen.endTime}
                        onClose={() =>
                            setIsDatePickerOpen({
                                startTime: false,
                                endTime: false,
                            })
                        }
                        value={times.endTime}
                        clearable
                        onChange={changeEndTime}
                        minDate={times.startTime}
                        maxDate={moment.utc(`${night}T12:00:00`)}
                        TextFieldComponent={() => (
                            <TextField
                                className={classes.datePickerTextField}
                                onClick={() =>
                                    setIsDatePickerOpen({
                                        startTime: false,
                                        endTime: true,
                                    })
                                }
                                value={dateToTimeAndDateString(times.endTime)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccessTime />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                    type: "button",
                                }}
                            />
                        )}
                    />
                </MuiPickersUtilsProvider>
            </Typography>
        </Grid>
    );
};

const BottomFilterForm: React.FC<BottomFilterFormProps> = ({
    filters,
    handleFilterChange,
}) => {
    const classes = styles({});

    return (
        <Grid item className={classes.bottomFilterContainer}>
            <ValuesFilterFormControl
                value="heart_rate_fitbit_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="spo2_minimum_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="hrv_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="activity_fitbit_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
            <ValuesFilterFormControl
                value="cumulative_activity_fitbit_values"
                type="detailed"
                filters={filters}
                onChange={handleFilterChange}
            />
        </Grid>
    );
};

export const PatientNightDetailsGraphFooter: React.FC<PatientNightDetailsGraphFooterProps> =
    ({
        handleBlur,
        handleInputChange,
        changeDipData,
        amountOfDips,
        dipData,
        filters,
        handleFilterChange,
    }) => (
        <Grid container>
            <Grid container alignItems="center" justify="space-between">
                <Grid container style={{ width: "400px" }}>
                    <LimitTypeSelector
                        dipData={dipData}
                        changeDipData={changeDipData}
                    />
                    <DipsDisplay amountOfDips={amountOfDips} />
                </Grid>
                <TopFilterForm
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            </Grid>
            <Grid container alignItems="center" justify="space-between">
                <Grid container style={{ width: "200px" }}>
                    <LimitNumberSelector
                        dipData={dipData}
                        handleBlur={handleBlur}
                        handleInputChange={handleInputChange}
                    />
                </Grid>
                <BottomFilterForm
                    filters={filters}
                    handleFilterChange={handleFilterChange}
                />
            </Grid>
        </Grid>
    );
