import MomentUtils from "@date-io/moment";
import {
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {
    AddCircleOutlined,
    Cancel,
    DeleteForever,
    Edit,
    EventOutlined,
    ExpandMore,
    GetApp,
    Refresh,
    Save,
    TodayOutlined,
    Visibility,
    VisibilityOff,
} from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FitbitIcon from "components/FitbitIcon";
import RoundedButton from "components/RoundedButton/RoundedButton";
import config from "config";
import { dateToDayMonthYearString } from "helpers/dateToString";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ErrorList } from "components/PatientTable/PatientTable.components";
import { filterAndTrimErrors } from "store/patient/patient.actions";
import { patientSettingKeyToString } from "../../helpers/patientSettings";
import { PatientSetting } from "../../types/patientdata";
import { DateBooleans } from "../PatientOverviewBarChart/PatientOverviewBarChart.types";
import styles from "./TableOptionsMenu.styles";
import {
    AddPatientSettingProps,
    ArchivePatientFormProps,
    CsvFooterProps,
    CsvFormProps,
    CsvHeaderProps,
    PasswordChangeFormProps,
    PatientChangeNameFormProps,
    PatientSettingProps,
    PatientSettingsFormProps,
    PopupFooterProps,
    PopupHeaderProps,
} from "./TableOptionsMenu.types";

export const PopupHeader: React.FC<PopupHeaderProps> = ({
    enabledFitbit,
    patient,
    date,
}) => {
    const classes = styles({});

    return (
        <Grid
            className={classes.formContainer}
            container
            justify="space-between"
        >
            <Grid item>
                <Typography variant="h5" className={classes.popupText}>
                    {patient.name}
                </Typography>
                <Typography variant="body1" className={classes.popupText}>
                    Laatste invoer
                </Typography>
                <Typography variant="body1">
                    {enabledFitbit
                        ? date
                        : "Fitbit is uitgeschakeld voor deze organisatie"}
                </Typography>
            </Grid>
            <Grid item>
                {enabledFitbit && (
                    <RoundedButton
                        text="Koppel fitbit"
                        onClick={() => {
                            window.location.href = `${config.baseURL}/auth/${patient.name}`;
                            return null;
                        }}
                        color="lightBlue"
                        Icon={FitbitIcon}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export const CsvHeader: React.FC<CsvHeaderProps> = ({ patient }) => {
    const classes = styles({});

    return (
        <Grid className={classes.formContainer} container justify="center">
            <Grid item>
                <Typography variant="h5" className={classes.popupTextCsv}>
                    {patient}
                </Typography>
                <Typography variant="body1" className={classes.popupTextCsv}>
                    Download CSV voor Gemiddelde data
                </Typography>
            </Grid>
        </Grid>
    );
};

export const CsvForm: React.FC<CsvFormProps> = ({
    handleDateChange,
    dates,
}) => {
    const classes = styles({});
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<DateBooleans>({
        startDate: false,
        endDate: false,
    });
    const openDatePicker = (props: keyof DateBooleans) => {
        setIsDatePickerOpen({
            ...isDatePickerOpen,
            [props]: true,
        });
    };
    const closeDatePicker = (props: keyof DateBooleans) => {
        setIsDatePickerOpen({
            ...isDatePickerOpen,
            [props]: false,
        });
    };
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <Grid
                container
                direction="row"
                justify="center"
                className={classes.formContainer}
            >
                <Grid item>
                    <DatePicker
                        open={isDatePickerOpen.startDate}
                        onClose={() => closeDatePicker("startDate")}
                        value={dates.startDate}
                        onChange={(date) => handleDateChange(date, "startDate")}
                        format="dd/MM/yyyy"
                        TextFieldComponent={() => (
                            <TextField
                                className={classes.datePickerTextField}
                                onClick={() => openDatePicker("startDate")}
                                value={dateToDayMonthYearString(
                                    dates.startDate,
                                    "DD/MM/YY"
                                )}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TodayOutlined />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                    type: "button",
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        open={isDatePickerOpen.endDate}
                        onClose={() => closeDatePicker("endDate")}
                        value={dates.endDate}
                        onChange={(date) => handleDateChange(date, "endDate")}
                        format="dd/MM/yyyy"
                        TextFieldComponent={() => (
                            <TextField
                                className={classes.datePickerTextField}
                                onClick={() => openDatePicker("endDate")}
                                value={dateToDayMonthYearString(
                                    dates.endDate,
                                    "DD/MM/YY"
                                )}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EventOutlined />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                    type: "button",
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export const PatientSettingItem: React.FC<PatientSettingProps> = ({
    patientName,
    setting,
    changePatientSetting,
    deletePatientSetting,
}) => {
    const classes = styles({});
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState<number>(setting.value);

    useEffect(() => {
        setNewValue(setting.value);
    }, [setting]);

    const save = () => {
        changePatientSetting({
            user_name: patientName,
            setting_key: setting.setting_key,
            setting_value: newValue,
        });
        setEditMode(false);
    };

    const remove = () => {
        deletePatientSetting({
            user_name: patientName,
            setting_key: setting.setting_key,
        });
    };

    return (
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: "20px" }}
        >
            <Grid item style={{ width: "fit-content" }}>
                <Typography variant="body1" className={classes.popupText}>
                    {patientSettingKeyToString(setting.setting_key)}
                </Typography>
            </Grid>
            <Grid
                item
                container
                direction="row"
                style={{ width: "fit-content" }}
            >
                <Tooltip
                    title={
                        editMode ? (
                            ""
                        ) : (
                            <Typography>
                                Om deze waarde te bewerken druk op de bewerk
                                knop
                            </Typography>
                        )
                    }
                    placement="top"
                >
                    <Grid item>
                        <Input
                            className={classes.settingNumberSelector}
                            value={newValue}
                            onChange={(event) =>
                                setNewValue(
                                    event.target.value === ""
                                        ? newValue
                                        : Number(event.target.value)
                                )
                            }
                            inputProps={{
                                type: "number",
                                style: {
                                    textAlign: "center",
                                    width: "60px",
                                },
                            }}
                            disableUnderline
                            disabled={!editMode}
                        />
                    </Grid>
                </Tooltip>
                {editMode ? (
                    <>
                        <Grid item>
                            <RoundedButton
                                onClick={save}
                                color="darkBlue"
                                margin="smallLeft"
                                tooltipText="Nieuwe waarde opslaan"
                            >
                                <Save />
                            </RoundedButton>
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                onClick={() => setEditMode(false)}
                                color="red"
                                margin="smallLeft"
                                tooltipText="Bewerken annuleren"
                            >
                                <Cancel />
                            </RoundedButton>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item>
                            <RoundedButton
                                onClick={() => setEditMode(true)}
                                color="darkBlue"
                                margin="smallLeft"
                                tooltipText="Waarde bewerken"
                            >
                                <Edit />
                            </RoundedButton>
                        </Grid>
                        <Grid item>
                            <RoundedButton
                                onClick={remove}
                                color="red"
                                margin="smallLeft"
                                tooltipText="Instelling verwijderen"
                            >
                                <DeleteForever />
                            </RoundedButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export const AddPatientSetting: React.FC<AddPatientSettingProps> = ({
    patientName,
    changePatientSetting,
    settingKeys,
    settings,
}) => {
    const classes = styles({});
    const [newSetting, setNewSetting] = useState<PatientSetting>({
        setting_key: "",
        value: 0,
    });
    const [error, setError] = useState("");
    const [mode, setMode] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onChangeHandler = (
        event: React.ChangeEvent<{ value: unknown; name?: string }>
    ) => {
        if (event.target.name && newSetting[event.target.name]) {
            setNewSetting({
                ...newSetting,
                [event.target.name]: event.target.value,
            });
        }
    };

    const addSetting = () => {
        setNewSetting({
            setting_key: settingKeys.filter(
                (x) => !settings.map((y) => y.setting_key).includes(x)
            )[0],
            value: 0,
        });
        setError("");
        setMode(true);
    };

    const save = () => {
        if (
            settings.find(
                (setting) => setting.setting_key === newSetting.setting_key
            )
        ) {
            setError("Instelling bestaat al, pas hierboven aan");
        } else {
            changePatientSetting({
                user_name: patientName,
                setting_key: newSetting.setting_key,
                setting_value: newSetting.value,
            });
            setMode(false);
        }
    };

    const isError = error !== "";

    return (
        <Grid
            container
            direction="row"
            justify={mode ? "space-between" : "flex-end"}
            alignItems="center"
            style={{ marginBottom: "20px" }}
        >
            {mode ? (
                <>
                    <Grid item style={{ width: "fit-content" }}>
                        {isError && <InputLabel error>{error}</InputLabel>}
                        <Select
                            name="setting_key"
                            onChange={onChangeHandler}
                            className={classes.settingTypeSelector}
                            IconComponent={() => (
                                <ExpandMore onClick={handleOpen} />
                            )}
                            disableUnderline
                            open={open}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            value={newSetting.setting_key}
                        >
                            {settingKeys
                                .filter(
                                    (x) =>
                                        !settings
                                            .map((y) => y.setting_key)
                                            .includes(x)
                                )
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((settingKey) => (
                                    <MenuItem value={settingKey}>
                                        {patientSettingKeyToString(settingKey)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        style={{ width: "fit-content" }}
                    >
                        <Grid item>
                            <Input
                                className={classes.settingNumberSelector}
                                value={newSetting.value}
                                onChange={(event) =>
                                    setNewSetting({
                                        ...newSetting,
                                        value:
                                            event.target.value === ""
                                                ? newSetting.value
                                                : Number(event.target.value),
                                    })
                                }
                                inputProps={{
                                    min: 0,
                                    type: "number",
                                    style: {
                                        textAlign: "center",
                                        width: "50px",
                                    },
                                }}
                                disableUnderline
                            />
                        </Grid>
                        <Tooltip title="Nieuwe instelling opslaan">
                            <Grid item>
                                <RoundedButton
                                    onClick={save}
                                    color="darkBlue"
                                    margin="smallLeft"
                                >
                                    <Save />
                                </RoundedButton>
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Toevoegen annuleren">
                            <Grid item>
                                <RoundedButton
                                    onClick={() => setMode(false)}
                                    color="red"
                                    margin="smallLeft"
                                >
                                    <Cancel />
                                </RoundedButton>
                            </Grid>
                        </Tooltip>
                    </Grid>
                </>
            ) : (
                <Grid item>
                    <RoundedButton
                        onClick={addSetting}
                        color="darkBlue"
                        text="Voeg instelling toe"
                        Icon={AddCircleOutlined}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export const PatientSettingForm: React.FC<PatientSettingsFormProps> = ({
    patientName,
    settings,
    getPatientSettings,
    settingKeys,
    getPatientSettingKeys,
    deletePatientSetting,
    changePatientSetting,
    errors,
    cronHistoryFitbitDataByPatientName,
    cronHistoryNotificationsByPatientName,
    open,
    clearPatientErrors,
}) => {
    const classes = styles({});
    const [loadingCircles, setLoadingCircles] = useState({
        name: "none",
        loading: false,
    });
    const isLoading = (name: string) => {
        if (name === loadingCircles.name) {
            return loadingCircles.loading;
        }
        return false;
    };
    const resetLoadingCircles = () => {
        setLoadingCircles({
            name: "none",
            loading: false,
        });
    };
    const cronNotifications = async () => {
        setLoadingCircles({
            name: "notifications",
            loading: true,
        });
        await cronHistoryNotificationsByPatientName(patientName);
        resetLoadingCircles();
    };
    const cronFitbitData = async () => {
        setLoadingCircles({
            name: "fitbit",
            loading: true,
        });
        await cronHistoryFitbitDataByPatientName(patientName);
        resetLoadingCircles();
    };

    useEffect(() => {
        getPatientSettings(patientName);
        getPatientSettingKeys();
    }, [getPatientSettings, getPatientSettingKeys, patientName]);
    return (
        <div>
            <ErrorList
                type="api.history"
                errors={errors}
                open={open}
                clearPatientErrors={clearPatientErrors}
            />
            <br />
            <Grid container direction="row" justifyContent="flex-end">
                <Grid item>
                    <RoundedButton
                        onClick={cronNotifications}
                        loading={isLoading("notifications")}
                        color="darkBlue"
                        Icon={Refresh}
                        text="Herbereken"
                        tooltipText="Herberekenen van instelingen voor de afgelopen 30 dagen, dit kan een paar seconden duren"
                    />
                </Grid>
                <Grid item>
                    <RoundedButton
                        onClick={cronFitbitData}
                        loading={isLoading("fitbit")}
                        color="darkBlue"
                        Icon={Refresh}
                        text="Gegevens ophalen"
                        tooltipText="Fitbit gegevens ophalen van de afgelopen 30 dagen. LET OP: dit kan maar een paar keer per uur worden uitgevoerd, gebruik deze knop alleen als er data mist die in de Fitbit applicatie wel te zien is."
                    />
                </Grid>
            </Grid>
            <br />
            <Grid item>
                <Typography className={classes.formTitle} variant="h6">
                    Patient instellingen
                </Typography>
            </Grid>
            <br />
            {settings.length === 0 ? (
                <Typography>Geen instellingen</Typography>
            ) : (
                settings
                    .sort((a, b) =>
                        patientSettingKeyToString(a.setting_key) >
                        patientSettingKeyToString(b.setting_key)
                            ? 1
                            : -1
                    )
                    .map((setting) => (
                        <PatientSettingItem
                            patientName={patientName}
                            setting={setting}
                            changePatientSetting={changePatientSetting}
                            deletePatientSetting={deletePatientSetting}
                        />
                    ))
            )}
            {settings.length < settingKeys.length && (
                <AddPatientSetting
                    patientName={patientName}
                    changePatientSetting={changePatientSetting}
                    settingKeys={settingKeys}
                    settings={settings}
                />
            )}
        </div>
    );
};

export const PatientChangeNameForm: React.FC<PatientChangeNameFormProps> = ({
    errors,
    name,
    handleTextfieldChange,
}) => {
    const classes = styles({});
    const nameError = filterAndTrimErrors(errors, "local.name").length !== 0;
    return (
        <div className={classes.formContainer}>
            <Typography className={classes.formTitle} variant="h6">
                Naam wijzigen
            </Typography>
            <FormControl className={classes.textField}>
                <InputLabel
                    error={nameError}
                    className={classes.formLabel}
                    htmlFor="filled-password-new"
                >
                    Naam
                </InputLabel>
                <FilledInput
                    id="filled-password-new"
                    type="text"
                    value={name.name}
                    onChange={handleTextfieldChange("name")}
                    error={nameError}
                />
                {nameError && <FormHelperText error>{errors}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({
    errors,
    showPassword,
    password,
    handlePasswordChange,
    handleClickShowPassword,
}) => {
    const classes = styles({});
    const newError = false; // errors.new !== "";
    const repeatError =
        filterAndTrimErrors(errors, "local.repeat").length !== 0;
    return (
        <div className={classes.formContainer}>
            <Typography className={classes.formTitle} variant="h6">
                Wachtwoord wijzigen
            </Typography>
            <FormControl className={classes.textField}>
                <InputLabel
                    error={newError}
                    className={classes.formLabel}
                    htmlFor="filled-password-new"
                >
                    Nieuw wachtwoord
                </InputLabel>
                <FilledInput
                    id="filled-password-new"
                    type={showPassword.new ? "text" : "password"}
                    value={password.new}
                    onChange={handlePasswordChange("new")}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => handleClickShowPassword("new")}
                                edge="end"
                            >
                                {showPassword.new ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                    error={newError}
                />
                {newError && <FormHelperText error>{errors}</FormHelperText>}
            </FormControl>
            <FormControl className={classes.textField}>
                <InputLabel
                    error={repeatError}
                    className={classes.formLabel}
                    htmlFor="filled-password-repeat"
                >
                    Herhaal wachtwoord
                </InputLabel>
                <FilledInput
                    id="filled-password-repeat"
                    type={showPassword.repeat ? "text" : "password"}
                    value={password.repeat}
                    onChange={handlePasswordChange("repeat")}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() =>
                                    handleClickShowPassword("repeat")
                                }
                                edge="end"
                            >
                                {showPassword.repeat ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                    error={repeatError}
                />
                {repeatError && <FormHelperText error>{errors}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export const RemovePatientForm: React.FC<ArchivePatientFormProps> = ({
    errors,
    title,
    buttonText,
    textFieldValues,
    handleTextfieldChange,
    removePatient,
    prop,
}) => {
    const classes = styles({});
    const error = filterAndTrimErrors(errors, "local.archive").length !== 0;

    return (
        <div className={classes.formContainer}>
            <Typography className={classes.formTitle} variant="h6">
                {title}
            </Typography>
            <FormControl className={classes.textField}>
                <InputLabel
                    error={error}
                    className={classes.formLabel}
                    htmlFor={`filled-password-${prop}`}
                >
                    Bevestig door de patientnaam in te vullen
                </InputLabel>
                <FilledInput
                    id={`filled-password-${prop}`}
                    type="text"
                    value={textFieldValues[prop]}
                    onChange={handleTextfieldChange(prop)}
                    error={error}
                />
                {error && (
                    <FormHelperText error={error}>
                        {errors[prop]}
                    </FormHelperText>
                )}
                <div className={classes.formButton}>
                    <RoundedButton
                        text={buttonText}
                        onClick={removePatient}
                        color="red"
                        disabled={textFieldValues[prop] === ""}
                    />
                </div>
            </FormControl>
        </div>
    );
};

export const PopupFooter: React.FC<PopupFooterProps> = ({
    savePopupData,
    handlePopupClose,
}) => {
    const classes = styles({});

    return (
        <Grid
            className={classes.formContainer}
            container
            justify="space-between"
        >
            <Grid item>
                <RoundedButton
                    text="Gegevens opslaan"
                    onClick={savePopupData}
                    color="darkBlue"
                />
            </Grid>
            <Grid item>
                <RoundedButton
                    text="Annuleren"
                    onClick={handlePopupClose}
                    color="grey"
                />
            </Grid>
        </Grid>
    );
};

export const CsvFooter: React.FC<CsvFooterProps> = ({
    createCsv,
    handlePopupClose,
    loading,
}) => {
    const classes = styles({});

    return (
        <Grid
            className={classes.formContainer}
            container
            justify="space-between"
        >
            <Grid item>
                <RoundedButton
                    text="Download CSV"
                    onClick={createCsv}
                    color="darkBlue"
                    Icon={GetApp}
                    margin="center"
                    loading={loading}
                    disabled={loading}
                />
            </Grid>
            <Grid item>
                <RoundedButton
                    text="Annuleren"
                    onClick={handlePopupClose}
                    color="grey"
                    margin="center"
                />
            </Grid>
        </Grid>
    );
};
