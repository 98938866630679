import {
    DailyAverageGraphData,
    DetailedNightData,
    RealTimeNoninData,
    ValueAndTime,
} from "types/patientdata";
import { dataColors } from "components/PatientNightDetailsGraph/PatientNightDetailsGraph.styles";

interface DataSetObject {
    type: string;
    label: string;
    backgroundColor: string;
    pointBorderColor: string;
    borderColor: string;
    pointBorderWidth: number;
    pointHoverRadius: number;
    pointHoverBackgroundColor: string;
    pointHoverBorderColor: string;
    pointHoverBorderWidth: number;
    pointRadius: number;
    pointHitRadius: number;
    tension: number;
    yAxisID: string;
    showLine: boolean;
    fill: any;
    steppedLine?: any;
    cubisInterpolationMode?: string;
    spanGaps?: boolean;
    labels?: string[];
    data: { x: any; y: number }[];
}

export const getData = (
    valueAndTimeData: ValueAndTime[],
    label: string,
    type: string,
    yAxisNumber: number,
    fill: boolean,
    valueType:
        | keyof DetailedNightData
        | keyof RealTimeNoninData
        | keyof DailyAverageGraphData
): DataSetObject => {
    if (valueType === "sleep_stage_values") {
        return {
            type,
            label,
            backgroundColor: dataColors[valueType],
            pointBorderColor: dataColors[valueType],
            borderColor: dataColors[valueType],
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: dataColors[valueType],
            pointHoverBorderColor: dataColors[valueType],
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            tension: 0,
            steppedLine: true,
            yAxisID: `y-axis-${yAxisNumber}`,
            showLine: true,
            fill: "start",
            data: valueAndTimeData,
        };
    }
    return {
        type,
        label,
        backgroundColor: dataColors[valueType],
        pointBorderColor: dataColors[valueType],
        borderColor: dataColors[valueType],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: dataColors[valueType],
        pointHoverBorderColor: dataColors[valueType],
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        tension: 0,
        yAxisID: `y-axis-${yAxisNumber}`,
        showLine: true,
        fill,
        data: valueAndTimeData,
    };
};

export const getAverageGraphDataSet = (
    dailyAverageGraphData: DailyAverageGraphData
) => {
    const result: DataSetObject[] = [];
    result.push(dailyAverageGraphData.heart_rate_nonin_average);
    result.push(dailyAverageGraphData.spo2_average_average);
    result.push(dailyAverageGraphData.spo2_minimum_average);
    result.push(dailyAverageGraphData.hrv_average);
    if (dailyAverageGraphData.resting_heart_rate)
        result.push(dailyAverageGraphData.resting_heart_rate);
    if (dailyAverageGraphData.total_sleeptime_fitbit)
        result.push(dailyAverageGraphData.total_sleeptime_fitbit);
    if (dailyAverageGraphData.weight) result.push(dailyAverageGraphData.weight);
    if (dailyAverageGraphData.total_activity_fitbit)
        result.push(dailyAverageGraphData.total_activity_fitbit);
    if (dailyAverageGraphData.breathing_rate)
        result.push(dailyAverageGraphData.breathing_rate);
    if (dailyAverageGraphData.temperature_difference)
        result.push(dailyAverageGraphData.temperature_difference);
    return {
        datasets: result.filter((x) => x !== undefined && x !== null),
    };
};

export const getRealTimeDataSet = (realTimeNoninData: RealTimeNoninData) => {
    const result: DataSetObject[] = [];
    result.push(realTimeNoninData.heart_rate_nonin_values);
    result.push(realTimeNoninData.spo2_values);
    result.push(realTimeNoninData.pai_values);
    result.push(realTimeNoninData.hrv_values);
    return {
        datasets: result.filter((x) => x !== undefined && x !== null),
    };
};

export const getDataSet = (detailedNightData: DetailedNightData, canvas) => {
    const result: DataSetObject[] = [];

    // creating gradient for sleepstages
    const ctx = canvas.getContext("2d");
    const { height } = canvas;
    const gradientStroke = ctx.createLinearGradient(
        0,
        height * 0.45,
        0,
        height
    );
    gradientStroke.addColorStop(0, "rgba(238, 63, 118, 0.5)");
    gradientStroke.addColorStop(0.8, "rgba(238, 63, 118, 0.5)");

    result.push(detailedNightData.heart_rate_nonin_values);
    result.push(detailedNightData.spo2_average_values);
    result.push(detailedNightData.pai_values);
    if (detailedNightData.sleep_stage_values)
        result.push({
            ...detailedNightData.sleep_stage_values,
            backgroundColor: gradientStroke,
            pointBorderColor: gradientStroke,
            borderColor: gradientStroke,
            pointHoverBackgroundColor: gradientStroke,
            pointHoverBorderColor: gradientStroke,
        });

    if (detailedNightData.heart_rate_fitbit_values)
        result.push(detailedNightData.heart_rate_fitbit_values);
    result.push(detailedNightData.spo2_minimum_values);
    result.push(detailedNightData.hrv_values);
    if (detailedNightData.activity_fitbit_values)
        result.push(detailedNightData.activity_fitbit_values);
    if (detailedNightData.cumulative_activity_fitbit_values)
        result.push(detailedNightData.cumulative_activity_fitbit_values);
    return {
        datasets: result.filter((x) => x !== undefined && x !== null),
    };
};

// Min/Max value calculators for hr(using both nonin and fitbit), spo2(with a maxmax of 100 and a minmin of 0)
export const getMaxHeartRateFromData = (
    deviation: number,
    noninData: ValueAndTime[] | undefined,
    fitbitData: ValueAndTime[] | undefined
): number => {
    let result = 170; // max value when no data is available or displayed
    const noninMax =
        noninData?.length !== 0 &&
        noninData?.reduce((prev, curr) => (prev.y > curr.y ? prev : curr)).y;
    const fitbitMax =
        fitbitData?.length !== 0 &&
        fitbitData?.reduce((prev, curr) => (prev.y > curr.y ? prev : curr)).y;
    if (!fitbitMax && noninMax) result = noninMax;
    if (!noninMax && fitbitMax) result = fitbitMax;
    if (fitbitMax && noninMax && noninMax > fitbitMax) result = noninMax;
    if (fitbitMax && noninMax && noninMax < fitbitMax) result = fitbitMax;
    return result + deviation;
};

export const getMinHeartRateFromData = (
    deviation: number,
    noninData: ValueAndTime[] | undefined,
    fitbitData: ValueAndTime[] | undefined
): number => {
    let result = 40; // min value when no data is available or displayed
    const noninMin =
        noninData?.length !== 0 &&
        noninData?.reduce((prev, curr) => (prev.y < curr.y ? prev : curr)).y;
    const fitbitMin =
        fitbitData?.length !== 0 &&
        fitbitData?.reduce((prev, curr) => (prev.y < curr.y ? prev : curr)).y;
    if (!fitbitMin && noninMin) result = noninMin;
    if (!noninMin && fitbitMin) result = fitbitMin;
    if (fitbitMin && noninMin && noninMin < fitbitMin) result = noninMin;
    if (fitbitMin && noninMin && noninMin > fitbitMin) result = fitbitMin;
    return result - deviation;
};
// This function exists in case the user turns off the spo2 minimum data in the graph
export const getMinSpO2FromData = (
    deviation: number,
    spO2minData: ValueAndTime[] | undefined,
    spO2AvgData: ValueAndTime[] | undefined
): number => {
    let result = 70; // min value when no data is available or displayed
    if (spO2minData && spO2minData.length !== 0) {
        result = spO2minData.reduce((prev, curr) =>
            prev.y < curr.y ? prev : curr
        ).y;
    } else if (spO2AvgData && spO2AvgData.length !== 0) {
        result = spO2AvgData.reduce((prev, curr) =>
            prev.y < curr.y ? prev : curr
        ).y;
    }
    result -= deviation; // deviation to make the graph look better
    if (result < 0) result = 0; // % saturation of oxygen inside blood cant go below 0
    return result;
};

export const getMaxSpO2FromData = (
    deviation: number,
    spO2MinData: ValueAndTime[] | undefined,
    spO2MaxData: ValueAndTime[] | undefined
): number => {
    let result = 100; // max value when no data is available or displayed
    if (spO2MaxData && spO2MaxData.length !== 0) {
        result = spO2MaxData.reduce((prev, curr) =>
            prev.y > curr.y ? prev : curr
        ).y;
    } else if (spO2MinData && spO2MinData.length !== 0) {
        result = spO2MinData.reduce((prev, curr) =>
            prev.y > curr.y ? prev : curr
        ).y;
    }
    result += deviation;
    if (result > 100) result = 100;
    return result;
};
