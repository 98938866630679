import Config from "api/types/Config";
import {
    ArchivePatientResponseData,
    ArchivePatientRequest,
    ChangePasswordRequest,
    ChangePasswordResponseData,
    CreateNewPatientRequest,
    CreateNewPatientResponseData,
    GetDailyAveragesRequest,
    GetDailyAveragesResponseData,
    GetDetailedNightRequest,
    GetDetailedNightResponseData,
    DeletePatientRequest,
    DeletePatientResponseData,
    GetRealTimeNoninDataRequest,
    GetRealTimeNoninDataResponse,
    GetSettingKeysResponse,
    PatientSettingsResponse,
    ChangePatientSettingRequest,
    DeletePatientSettingRequest,
    CronRefreshResponse,
    CronRefreshRequest,
    CronSettingsUpdateRequest,
    CronSettingsUpdateResponse,
    ChangeNameRequest,
    CronHistoryNotificationsByPatientNameResponse,
    CronDailyNotificationsByPatientNameResponse,
    CronHistoryFitbitDataByPatientNameResponse,
    CronDailyFitbitDataByPatientNameResponse,
} from "api/types/Patient";
import requireJWT from "api/internal/requirejwt";
import jsonCheck from "api/internal/jsoncheck";

import {
    archivePatientRequestDecoder,
    archivePatientResponseDataDecoder,
    changePasswordRequestDecoder,
    changePasswordResponseDataDecoder,
    changePatientSettingRequestDecoder,
    createNewPatientRequestDecoder,
    createNewPatientResponseDataDecoder,
    deletePatientRequestDecoder,
    deletePatientResponseDataDecoder,
    getDailyAveragesResponseDataDecoder,
    getDetailedNightResponseDataDecoder,
    getRealTimeNoninDataResponseDecoder,
    getSettingKeysResponseDecoder,
    patientSettingsResponseDecoder,
    deletePatientSettingRequestDecoder,
    cronRefreshResponseDecoder,
    cronSettingsUpdateResponseDecoder,
    changeNameRequestDecoder,
    cronHistoryNotificationsByPatientNameResponseDecoder,
    cronDailyNotificationsByPatientNameResponseDecoder,
    cronHistoryFitbitDataByPatientNameResponseDecoder,
    cronDailyFitbitDataByPatientNameResponseDecoder,
} from "api/internal/decoders";
import { PostEndpoints, GetEndpoints } from "api/internal/endpoints";
import throwOrReturn from "api/internal/throworreturn";
import { post, get } from "api/internal/calls";

const changePasswordApiCall = async (
    config: Config,
    passwordChangeData: ChangePasswordRequest
): Promise<ChangePasswordResponseData> => {
    requireJWT(config);
    jsonCheck(passwordChangeData, changePasswordRequestDecoder);
    const response = await post<
        ChangePasswordRequest,
        ChangePasswordResponseData
    >(config, PostEndpoints.ChangePassword, passwordChangeData);
    return throwOrReturn(response, changePasswordResponseDataDecoder);
};

const changeNameApiCall = async (
    config: Config,
    nameChangeData: ChangeNameRequest
): Promise<null> => {
    requireJWT(config);
    jsonCheck(nameChangeData, changeNameRequestDecoder);
    const response = await post<ChangeNameRequest, null>(
        config,
        PostEndpoints.ChangeName,
        nameChangeData
    );
    return throwOrReturn(response, changePasswordResponseDataDecoder);
};

const archivePatientApiCall = async (
    config: Config,
    archivePatientData: ArchivePatientRequest
): Promise<ArchivePatientResponseData> => {
    requireJWT(config);
    jsonCheck(archivePatientData, archivePatientRequestDecoder);
    const response = await post<
        ArchivePatientRequest,
        ArchivePatientResponseData
    >(config, PostEndpoints.ArchivePatient, archivePatientData);
    return throwOrReturn(response, archivePatientResponseDataDecoder);
};

const deletePatientApiCall = async (
    config: Config,
    deletePatientData: DeletePatientRequest
): Promise<DeletePatientResponseData> => {
    requireJWT(config);
    jsonCheck(deletePatientData, deletePatientRequestDecoder);
    const response = await post<
        DeletePatientRequest,
        DeletePatientResponseData
    >(config, PostEndpoints.DeletePatient, deletePatientData);
    return throwOrReturn(response, deletePatientResponseDataDecoder);
};

const createNewPatientApiCall = async (
    config: Config,
    createNewPatientData: CreateNewPatientRequest
): Promise<CreateNewPatientResponseData> => {
    requireJWT(config);
    jsonCheck(createNewPatientData, createNewPatientRequestDecoder);
    const response = await post<
        CreateNewPatientRequest,
        CreateNewPatientResponseData
    >(config, PostEndpoints.CreateNewPatient, createNewPatientData);
    return throwOrReturn(response, createNewPatientResponseDataDecoder);
};

const getPatientSettingKeysApiCall = async (
    config: Config
): Promise<GetSettingKeysResponse> => {
    requireJWT(config);
    const response = await get<GetSettingKeysResponse>(
        config,
        GetEndpoints.GetSettingKeys
    );
    return throwOrReturn(response, getSettingKeysResponseDecoder);
};

const getPatientSettingsApiCall = async (
    config: Config,
    patientName: string
): Promise<PatientSettingsResponse> => {
    requireJWT(config);
    const endpoint = `${GetEndpoints.GetPatientSettings}/${patientName}`;
    const response = await get<PatientSettingsResponse>(config, endpoint);
    return throwOrReturn(response, patientSettingsResponseDecoder);
};

const changePatientSettingApiCall = async (
    config: Config,
    changePatientSettingData: ChangePatientSettingRequest
): Promise<PatientSettingsResponse> => {
    requireJWT(config);
    jsonCheck(changePatientSettingData, changePatientSettingRequestDecoder);
    const response = await post<
        ChangePatientSettingRequest,
        PatientSettingsResponse
    >(config, PostEndpoints.ChangePatientSetting, changePatientSettingData);
    return throwOrReturn(response, patientSettingsResponseDecoder);
};

const deletePatientSettingApiCall = async (
    config: Config,
    deletePatientSettingData: DeletePatientSettingRequest
): Promise<PatientSettingsResponse> => {
    requireJWT(config);
    jsonCheck(deletePatientSettingData, deletePatientSettingRequestDecoder);
    const response = await post<
        DeletePatientSettingRequest,
        PatientSettingsResponse
    >(config, PostEndpoints.DeletePatientSetting, deletePatientSettingData);
    return throwOrReturn(response, patientSettingsResponseDecoder);
};

const getDailyAveragesApiCall = async (
    config: Config,
    dailyAveragesRequest: GetDailyAveragesRequest
): Promise<GetDailyAveragesResponseData> => {
    requireJWT(config);
    const { patient_name, start_date, end_date } = dailyAveragesRequest;
    const endpoint = `${GetEndpoints.DailyAverages}/${patient_name}/${start_date}/${end_date}`;
    const response = await get<GetDailyAveragesResponseData>(config, endpoint);
    return throwOrReturn(response, getDailyAveragesResponseDataDecoder);
};

const getDetailedNightDataApiCall = async (
    config: Config,
    detailedNightRequest: GetDetailedNightRequest,
    noninOnly: boolean
): Promise<GetDetailedNightResponseData> => {
    requireJWT(config);
    const { patient_name, night_date } = detailedNightRequest;
    const endpoint = `${
        GetEndpoints.DetailedNight
    }/${patient_name}/${night_date}${noninOnly ? "/true" : ""}`;
    const response = await get<GetDetailedNightResponseData>(config, endpoint);
    return throwOrReturn(response, getDetailedNightResponseDataDecoder);
};

const getRealTimeNoninDataApiCall = async (
    config: Config,
    realTimeNoninRequest: GetRealTimeNoninDataRequest
): Promise<GetRealTimeNoninDataResponse> => {
    requireJWT(config);
    const { patient_name, moment_date, timeWindow } = realTimeNoninRequest;
    const endpoint = `${GetEndpoints.GetRealTimeNonin}/${patient_name}/${moment_date}/${timeWindow}`;
    const response = await get<GetRealTimeNoninDataResponse>(config, endpoint);
    return throwOrReturn(response, getRealTimeNoninDataResponseDecoder);
};

// cronHistoryNotificationsByPatientName
const cronHistoryNotificationsByPatientNameApiCall = async (
    config: Config,
    patient_name: string
): Promise<CronHistoryNotificationsByPatientNameResponse> => {
    requireJWT(config);
    const endpoint = `${
        GetEndpoints.CronHistoryNotificationsByPatientName
    }/${encodeURIComponent(patient_name)}`;
    const response = await get<CronHistoryNotificationsByPatientNameResponse>(
        config,
        endpoint
    );
    return throwOrReturn(
        response,
        cronHistoryNotificationsByPatientNameResponseDecoder
    );
};
// cronDailyNotificationsByPatientName
const cronDailyNotificationsByPatientNameApiCall = async (
    config: Config,
    patient_name: string
): Promise<CronDailyNotificationsByPatientNameResponse> => {
    requireJWT(config);
    const endpoint = `${
        GetEndpoints.CronDailyNotificationsByPatientName
    }/${encodeURIComponent(patient_name)}`;
    const response = await get<CronDailyNotificationsByPatientNameResponse>(
        config,
        endpoint
    );
    return throwOrReturn(
        response,
        cronDailyNotificationsByPatientNameResponseDecoder
    );
};

// cronHistoryFitbitDataByPatientName
const cronHistoryFitbitDataByPatientNameApiCall = async (
    config: Config,
    patient_name: string
): Promise<CronHistoryFitbitDataByPatientNameResponse> => {
    requireJWT(config);
    const endpoint = `${
        GetEndpoints.CronHistoryFitbitDataByPatientName
    }/${encodeURIComponent(patient_name)}`;
    const response = await get<CronHistoryFitbitDataByPatientNameResponse>(
        config,
        endpoint
    );
    return throwOrReturn(
        response,
        cronHistoryFitbitDataByPatientNameResponseDecoder
    );
};
// cronDailyFitbitDataByPatientName
const cronDailyFitbitDataByPatientNameApiCall = async (
    config: Config,
    patient_name: string
): Promise<CronDailyFitbitDataByPatientNameResponse> => {
    requireJWT(config);
    const endpoint = `${
        GetEndpoints.CronDailyFitbitDataByPatientName
    }/${encodeURIComponent(patient_name)}`;
    const response = await get<CronDailyFitbitDataByPatientNameResponse>(
        config,
        endpoint
    );
    return throwOrReturn(
        response,
        cronDailyFitbitDataByPatientNameResponseDecoder
    );
};

// deprecated functions
const cronRefreshApiCall = async (
    config: Config
): Promise<CronRefreshResponse> => {
    requireJWT(config);
    const request: CronRefreshRequest = { scheduled: false };
    const response = await post<CronRefreshRequest, CronRefreshResponse>(
        config,
        PostEndpoints.CronRefresh,
        request
    );
    return throwOrReturn(response, cronRefreshResponseDecoder);
};

const cronSettingsUpdateApiCall = async (
    config: Config,
    cronSettingsUpdateRequest: CronSettingsUpdateRequest
): Promise<CronSettingsUpdateResponse> => {
    requireJWT(config);
    const response = await post<
        CronSettingsUpdateRequest,
        CronSettingsUpdateResponse
    >(config, PostEndpoints.CronSettingsUpdate, cronSettingsUpdateRequest);
    return throwOrReturn(response, cronSettingsUpdateResponseDecoder);
};

export {
    changePasswordApiCall,
    changeNameApiCall,
    archivePatientApiCall,
    deletePatientApiCall,
    createNewPatientApiCall,
    getDailyAveragesApiCall,
    getDetailedNightDataApiCall,
    getRealTimeNoninDataApiCall,
    getPatientSettingKeysApiCall,
    getPatientSettingsApiCall,
    changePatientSettingApiCall,
    deletePatientSettingApiCall,
    cronRefreshApiCall,
    cronSettingsUpdateApiCall,
    cronHistoryFitbitDataByPatientNameApiCall,
    cronDailyFitbitDataByPatientNameApiCall,
    cronHistoryNotificationsByPatientNameApiCall,
    cronDailyNotificationsByPatientNameApiCall,
};
