import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { Alert } from "@material-ui/lab";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {
    ArrowDropDown,
    ArrowDropUp,
    Person,
    SortByAlpha,
    NotificationImportant,
    WatchSharp,
} from "@material-ui/icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { renderFlags } from "components/PatientOverviewTable/PatientOverviewTable.components";
import TableOptionsMenu from "components/TableOptionsMenu/TableOptionsMenu";
import {
    getDaysSinceDate,
    dateToDayMonthYearString,
} from "helpers/dateToString";
import { minutesToHourMinuteString } from "helpers/time";
import moment from "moment";
import React, { useState } from "react";
import { PatientOverview } from "types/patientdata";
import RoundedButton from "components/RoundedButton/RoundedButton";
import { removeWeightAlarm } from "store/user/user.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { ButtonColorCatalogue } from "components/RoundedButton/RoundedButton.styles";
import { filterAndTrimErrors } from "store/patient/patient.actions";
import {
    PatientTableBodyProps,
    PatientTableFooterProps,
    PatientTableHeaderProps,
    WeightCellProps,
} from "./PatientTable.types";
import styles from "./PatientTable.styles";

export const ErrorList: React.FC<{
    errors: string[];
    open: boolean;
    clearPatientErrors: (string) => void;
    type?: string;
}> = ({ errors, open, clearPatientErrors, type }) => (
    <>
        {type
            ? filterAndTrimErrors(errors, type).map((error) => (
                  <Collapse in={open}>
                      <Alert
                          style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: ButtonColorCatalogue.red.color,
                          }}
                          severity="error"
                          variant="filled"
                          action={
                              <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                      clearPatientErrors(error);
                                  }}
                              >
                                  <CloseIcon fontSize="inherit" />
                              </IconButton>
                          }
                      >
                          {error}
                      </Alert>
                  </Collapse>
              ))
            : errors.map((error) => (
                  <Collapse in={open}>
                      <Alert
                          style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              backgroundColor: ButtonColorCatalogue.red.color,
                          }}
                          severity="error"
                          variant="filled"
                          action={
                              <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                      clearPatientErrors(error);
                                  }}
                              >
                                  <CloseIcon fontSize="inherit" />
                              </IconButton>
                          }
                      >
                          {error}
                      </Alert>
                  </Collapse>
              ))}
    </>
);

export const PatientTableHeader: React.FC<PatientTableHeaderProps> = ({
    changeSorting,
}) => {
    const classes = styles();

    return (
        <TableHead className={classes.tableheader}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: "120px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("name")}
                    >
                        <Typography className={classes.tableHeaderItem}>
                            <SortByAlpha className={classes.icon} />
                        </Typography>
                    </Button>
                </TableCell>

                {/** 
                 * <TableCell align="left" style={{ minWidth: "180px" }}>
                    <Typography className={classes.options}>
                        Meldingen
                    </Typography>
                </TableCell>
                */}

                <TableCell align="center" style={{ minWidth: "64px" }}>
                    <Typography className={classes.options}>Status</Typography>
                </TableCell>

                <TableCell align="left" style={{ minWidth: "146px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("fitbit_cron_version")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Laatste data
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                        <Avatar
                            className={classes.fitbitIcon}
                            src={require("static/logo-fitbit-iconblue.png")}
                            alt="Fitbit"
                        />
                    </Button>
                </TableCell>

                <TableCell align="left" style={{ minWidth: "80px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("steps_last_day")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Stappen
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "120px" }}>
                    <Button
                        className={classes.button}
                        onClick={() =>
                            changeSorting("sleep_duration_last_night")
                        }
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Slaapduur
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>
                <TableCell align="left" style={{ minWidth: "160px" }}>
                    <Button
                        className={classes.button}
                        onClick={() => changeSorting("body_weight")}
                    >
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                        >
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropUp className={classes.sortIconTop} />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.tableHeaderItem}>
                                    Gewicht
                                </Typography>
                            </Grid>
                            <Grid item className={classes.sortIconContainer}>
                                <ArrowDropDown
                                    className={classes.sortIconBottom}
                                />
                            </Grid>
                        </Grid>
                    </Button>
                </TableCell>

                <TableCell align="right" style={{ minWidth: "64px" }}>
                    <Typography className={classes.options}>Opties</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

const RenderFlags: React.FC<{
    flags?: string[];
    day?: boolean;
    daysSinceWeighIn?: number | null;
    sleepPhaseCounter?: number | null;
}> = ({ flags, day, daysSinceWeighIn, sleepPhaseCounter }) =>
    renderFlags(flags, daysSinceWeighIn, day, sleepPhaseCounter);

const StyledTableRow = withStyles({
    root: {
        height: "65px",
        "&:nth-of-type(even)": {
            backgroundColor: "#f8f9fc",
        },
    },
})(TableRow);

const WeightCell: React.FC<WeightCellProps> = ({
    weight,
    alarm,
    patientName,
}) => {
    const [open, setOpen] = useState(false);
    const removeAlarmCall = async () => {
        await removeWeightAlarm(patientName);
        handleClickClose();
    };
    if (weight == null) return null;
    const classes = styles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickClose = () => {
        setOpen(false);
    };

    if (!alarm)
        return (
            <Typography className={classes.tableCellText}>
                {weight} kg
            </Typography>
        );
    return (
        <>
            <RoundedButton
                color="red"
                onClick={handleClickOpen}
                EndIcon={NotificationImportant}
                text={`${weight} kg`}
                margin="center"
            />
            <Dialog open={open} onClose={handleClickClose}>
                <DialogTitle style={{ textAlign: "center" }}>
                    Weet je zeker dat je het alarm wilt verwijderen?
                </DialogTitle>
                <Typography
                    style={{ padding: "16px 24px", textAlign: "center" }}
                >
                    Op ACCEPTEREN drukken betekent dat het alarm bij volgende
                    gewichtsmeting van het scherm verdwijnt als het volgende
                    gewicht wordt VEILIG geacht (de range is van tevoren
                    vastgesteld en verschilt per patiënt).
                </Typography>

                <Grid
                    container
                    direction="row"
                    style={{ padding: "16px 24px" }}
                    justifyContent="center"
                >
                    <RoundedButton
                        color="darkBlue"
                        onClick={removeAlarmCall}
                        text="Accepteren"
                        margin="center"
                    />
                    <RoundedButton
                        color="grey"
                        onClick={handleClickClose}
                        text="Annuleren"
                        margin="center"
                    />
                </Grid>
            </Dialog>
        </>
    );
};

export const PatientTableBody: React.FC<PatientTableBodyProps> = ({
    sortedPatients,
    rowsPerPage,
    page,
    goToPatientPage,
    loadingData,
    organisations,
    cronDailyFitbitDataByPatientName,
    cronDailyNotificationsByPatientName,
}) => {
    const classes = styles();
    const [loadingCircles, setLoadingCircles] = useState({
        name: "none",
        loading: false,
        type: "none",
    });
    const isLoading = (name: string, type: string) => {
        if (type === loadingCircles.type && name === loadingCircles.name) {
            return loadingCircles.loading;
        }
        return false;
    };
    const resetLoadingCircles = () => {
        setLoadingCircles({
            name: "none",
            loading: false,
            type: "none",
        });
    };

    if (loadingData) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <div
                    style={{
                        minHeight: "300px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </div>
            </Grid>
        );
    }

    const checkEnabledFitbit = (patient: PatientOverview) => {
        const organisation = organisations.find(
            (x) => x.name === patient.organisation
        );
        if (!organisation) {
            return false;
        }

        return organisation.enable_fitbit;
    };

    return (
        <TableBody>
            {sortedPatients.length !== 0 ? (
                (rowsPerPage > 0
                    ? sortedPatients.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                      )
                    : sortedPatients
                ).map((patient) => {
                    const lastUpdatedDate = dateToDayMonthYearString(
                        patient.fitbit_cron_version?.last_updated
                            ? moment.utc(
                                  patient.fitbit_cron_version?.last_updated
                              )
                            : null,
                        "YYYY-MM-DD"
                    );

                    const daysSinceWeighIn = getDaysSinceDate(
                        patient.last_weigh_in_date
                            ? moment.utc(patient.last_weigh_in_date)
                            : null
                    );

                    const weightAlarm =
                        patient.flags_last_night?.includes("weight_alarm");

                    const dailyFlagsWithoutAlarm =
                        patient.flags_last_night?.filter(
                            (flag) => flag !== "weight_alarm"
                        );

                    const dailyFitbitDataUpdate = async () => {
                        setLoadingCircles({
                            name: patient.name,
                            loading: true,
                            type: "fitbit",
                        });
                        await cronDailyFitbitDataByPatientName(patient.name);
                        resetLoadingCircles();
                    };
                    const dailyNotificationsUpdate = async () => {
                        setLoadingCircles({
                            name: patient.name,
                            loading: true,
                            type: "notifications",
                        });
                        await cronDailyNotificationsByPatientName(patient.name);
                        resetLoadingCircles();
                    };

                    return (
                        <StyledTableRow key={patient.id}>
                            <TableCell
                                align="left"
                                colSpan={1}
                                onClick={() => goToPatientPage(patient.name)}
                                className={classes.tableCell}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Grid item className={classes.avatar}>
                                        <Avatar className={classes.avatarIcon}>
                                            <Person />
                                        </Avatar>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="column"
                                        alignItems="flex-start"
                                        style={{ width: "auto" }}
                                    >
                                        <Grid item>
                                            <Typography
                                                className={classes.userTitle}
                                            >
                                                {patient.name}
                                            </Typography>
                                        </Grid>
                                        <RenderFlags
                                            flags={dailyFlagsWithoutAlarm}
                                            day
                                            daysSinceWeighIn={daysSinceWeighIn}
                                            sleepPhaseCounter={
                                                patient.sleep_phase_counter
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </TableCell>

                            {/*
                                <TableCell
                                align="left"
                                className={classes.flagCell}
                            >
                                <Grid item>
                                            <Typography
                                                className={classes.userTitle}
                                            >
                                                {patient.name}
                                            </Typography>
                                        </Grid>
                                    <RenderFlags
                                        flags={dailyFlagsWithoutAlarm}
                                        day
                                        daysSinceWeighIn={daysSinceWeighIn}
                                        sleepPhaseCounter={
                                            patient.sleep_phase_counter
                                        }
                                    />
                            </TableCell>
                            */}

                            <TableCell align="center">
                                <Typography className={classes.cronContainer}>
                                    <RoundedButton
                                        margin="none"
                                        loading={isLoading(
                                            patient.name,
                                            "fitbit"
                                        )}
                                        tooltipText={
                                            patient.fitbit_cron_version
                                                ?.completed_data_update
                                                ? "Gegevens van Fitbit t/m datum zijn compleet."
                                                : "De Fitbit gegevens van deze datum zijn nog incompleet. Druk op de rode knop met het horloge om deze gegevens in te lezen. "+
                                                    "Blijft deze knop rood dan zijn de gegevens nog niet klaar voor gebruik en kan het later opnieuw geprobeerd worden."
                                        }
                                        color={
                                            patient.fitbit_cron_version
                                                ?.completed_data_update
                                                ? "green"
                                                : "red"
                                        }
                                        onClick={dailyFitbitDataUpdate}
                                    >
                                        <WatchSharp />
                                    </RoundedButton>
                                    <RoundedButton
                                        margin="none"
                                        loading={isLoading(
                                            patient.name,
                                            "notifications"
                                        )}
                                        tooltipText={
                                            patient.fitbit_cron_version?.completed_notifications_update
                                                ? "Berekeningen uit Fitbit gegevens t/m datum zijn compleet."
                                                :  patient.fitbit_cron_version?.completed_data_update 
                                                ? "De berekeningen zijn incompleet, druk op de rekenmachine om de berekeningen van afgelopen dag/nacht uit te voeren."
                                                : "De horloge én de calculator knop zijn rood. De gegevens van Fitbit moeten nog worden in gelezen. Druk eerst op het horloge, nadat het laad icoon verdwijnt druk op de rekenmachine."
                                        }
                                        color={
                                            patient.fitbit_cron_version
                                                ?.completed_notifications_update
                                                ? "green"
                                                : "red"
                                        }
                                        onClick={dailyNotificationsUpdate}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCalculator}
                                            fontSize="1.2em"
                                        />
                                    </RoundedButton>
                                    <Typography className={classes.tableCellText}>
                                    <Tooltip
                                        title={
                                            <Typography>
                                               Dawny haalt dagelijks tussen 6:00AM en 12:00PM Fitbit gegevens op en doet berekeningen.
                                               Nadat de patiënt is opgestaan, heeft gesynchroniseerd (indien weegschaal aanwezig, zich heeft gewogen) worden de knoppen allebei groen.
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            fontSize="1.2em"
                                            style={{ marginLeft: 4 }}
                                        />
                                    </Tooltip>
                                    </Typography>
                                    
                                </Typography>
                            </TableCell>

                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient)
                                        ? lastUpdatedDate
                                        : "Uitgeschakeld"}
                                    <Tooltip
                                        title={
                                            <Typography>
                                                Betreft het niet de datum van
                                                vandaag of die van gisteren,
                                                kunt u naar de afgelopen 30
                                                dagen kijken. Dat kan via Opties
                                                &rarr; Beheer Patient
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            fontSize="1.2em"
                                            style={{ marginLeft: 4 }}
                                        />
                                    </Tooltip>
                                </Typography>
                            </TableCell>

                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient)
                                        ? patient.steps_last_day || "Onbekend"
                                        : "Fitbit Uitgeschakeld"}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient) &&
                                    patient.sleep_duration_last_night != null
                                        ? minutesToHourMinuteString(
                                              patient.sleep_duration_last_night
                                          ) // Convert ms to minutes first, then to a hours string.
                                        : "Fitbit Uitgeschakeld"}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography className={classes.tableCellText}>
                                    {checkEnabledFitbit(patient) ? (
                                        <WeightCell
                                            weight={patient.body_weight}
                                            alarm={weightAlarm}
                                            patientName={patient.name}
                                        />
                                    ) : (
                                        <Typography
                                            className={classes.tableCellText}
                                        >
                                            Fitbit Uitgeschakeld
                                        </Typography>
                                    )}
                                </Typography>
                            </TableCell>

                            <TableOptionsMenu
                                organisations={organisations}
                                patient={patient}
                                lastEntry={lastUpdatedDate}
                            />
                        </StyledTableRow>
                    );
                })
            ) : (
                <StyledTableRow>
                    <TableCell>Geen patientdata beschikbaar</TableCell>
                </StyledTableRow>
            )}
        </TableBody>
    );
};

export const PatientTableFooter: React.FC<PatientTableFooterProps> = ({
    sortedPatients,
    handlePageChange,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
}) => {
    const classes = styles();
    return (
        <TableFooter>
            <TableRow>
                <TablePagination
                    count={sortedPatients.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    colSpan={5}
                    rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Alle", value: -1 },
                    ]}
                    SelectProps={{
                        native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    className={classes.footer}
                    labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to > 0 ? to : count} van ${count}`
                    }
                    labelRowsPerPage="Rijen per pagina"
                />
            </TableRow>
        </TableFooter>
    );
};
