import { PatientDispatches, PatientState } from "./patient.types";
import PatientActions from "./patient.constants";

export const initialState: PatientState = {
    patients: [],
    currentPatient: {
        dailyAverages: null,
        dailyAveragesGraphData: null,
        detailedNight: null,
        realTimeNonin: null,
    },
    loadingDailyAverages: true,
    loadingDetailedNight: true,
    loadingPatients: true,
    settings: [],
    settingKeys: [],
    errors: [],
};

const patientReducer = (
    state: PatientState = initialState,
    action: PatientDispatches
): PatientState => {
    switch (action.type) {
        case PatientActions.SetPatientError:
            return {
                ...state,
                errors: [
                    ...state.errors.filter(
                        (err) => err !== action.payload.error
                    ),
                    action.payload.error,
                ],
            };
        case PatientActions.ClearPatientErrors:
            if (action.payload.error === "all") {
                return {
                    ...state,
                    errors: [],
                };
            }
            return {
                ...state,
                errors: [
                    ...state.errors.filter(
                        (err) => !err.includes(action.payload.error)
                    ),
                ],
            };
        case PatientActions.StartLoadingAverages:
            return {
                ...state,
                loadingDailyAverages: true,
            };
        case PatientActions.StartLoadingDetails:
            return {
                ...state,
                loadingDetailedNight: true,
            };
        case PatientActions.GetPatientList:
            return {
                ...state,
                patients: action.payload.patients,
                loadingPatients: false,
            };
        case PatientActions.GetDailyAverages:
            return {
                ...state,
                currentPatient: {
                    ...state.currentPatient,
                    dailyAverages: action.payload.dailyAverages,
                    dailyAveragesGraphData:
                        action.payload.dailyAverageGraphData,
                },
                loadingDailyAverages: false,
            };
        case PatientActions.GetRealTimeNonin:
            return {
                ...state,
                currentPatient: {
                    ...state.currentPatient,
                    realTimeNonin: action.payload.realTimeNonin,
                },
            };
        case PatientActions.GetDetailedNight:
            if (action.payload.initialCall) {
                return {
                    ...state,
                    currentPatient: {
                        ...state.currentPatient,
                        detailedNight: action.payload.detailedNight,
                    },
                    loadingDetailedNight: false,
                };
            }

            const oldNightWithFallbackNewNight =
                state.currentPatient.detailedNight ||
                action.payload.detailedNight;

            if (
                !oldNightWithFallbackNewNight ||
                !action.payload.detailedNight
            ) {
                return state; // Both were null or the new data is null, so we don't update.
            }

            return {
                ...state,
                currentPatient: {
                    ...state.currentPatient,
                    detailedNight: {
                        date: oldNightWithFallbackNewNight.date,
                        data: {
                            ...oldNightWithFallbackNewNight.data,
                            // Replace only to the new nonin values.
                            heart_rate_nonin_average:
                                action.payload.detailedNight.data
                                    .heart_rate_nonin_average,
                            spo2_average_average:
                                action.payload.detailedNight.data
                                    .spo2_average_average,
                            spo2_minimum_minimum:
                                action.payload.detailedNight.data
                                    .spo2_minimum_minimum,
                            heart_rate_nonin_values:
                                action.payload.detailedNight.data
                                    .heart_rate_nonin_values,
                            spo2_average_values:
                                action.payload.detailedNight.data
                                    .spo2_average_values,
                            spo2_minimum_values:
                                action.payload.detailedNight.data
                                    .spo2_minimum_values,
                            pai_values:
                                action.payload.detailedNight.data.pai_values,
                            hrv_values:
                                action.payload.detailedNight.data.hrv_values,
                        },
                    },
                },
                loadingDetailedNight: false,
            };
        case PatientActions.StartLoadingPatients:
            return {
                ...state,
                loadingPatients: true,
            };
        case PatientActions.SetPatientSettings:
            return {
                ...state,
                settings: action.payload.settings,
            };
        case PatientActions.SetPatientSettingKeys:
            return {
                ...state,
                settingKeys: action.payload.settingKeys,
            };
        default:
            return state;
    }
};

export default patientReducer;
