import React, { useEffect, useState } from "react";
import { InputBase, Paper, Table, TableContainer } from "@material-ui/core";
import { PatientOverview } from "types/patientdata";
import {
    getPatientList,
    cronDailyFitbitDataByPatientName,
    cronDailyNotificationsByPatientName,
    clearPatientErrors,
} from "store/patient/patient.actions";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReduxState from "store/store.types";
import { Search } from "@material-ui/icons";
import {
    ErrorList,
    PatientTableBody,
    PatientTableFooter,
    PatientTableHeader,
} from "./PatientTable.components";
import { PatientTableProps } from "./PatientTable.types";
import styles from "./PatientTable.styles";

const PatientTable: React.FC<PatientTableProps> = ({
    patients,
    currentOrganisations,
    getPatientList,
    loadingData,
    cronDailyFitbitDataByPatientName,
    cronDailyNotificationsByPatientName,
    errors,
    clearPatientErrors,
}) => {
    const classes = styles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const history = useHistory();
    const [sortingConfig, setSortingConfig] = useState<{
        sorting: keyof PatientOverview;
        sortingOrder: boolean;
    }>({ sorting: "name", sortingOrder: true });
    console.log(errors);

    const changeSorting = (item: keyof PatientOverview) => {
        if (sortingConfig.sorting === item) {
            setSortingConfig({
                ...sortingConfig,
                sortingOrder: !sortingConfig.sortingOrder,
            });
        } else {
            setSortingConfig({
                sorting: item,
                sortingOrder: true,
            });
        }
    };
    const compare = (
        a: PatientOverview,
        b: PatientOverview,
        key: keyof PatientOverview
    ) => {
        if (key === "body_weight") {
            if (a.body_weight && b.body_weight) {
                return b.body_weight - a.body_weight;
            }
            if (b.body_weight) {
                return 1;
            }
            if (a.body_weight) {
                return -1;
            }
            return 0;
        }
        if (key === "fitbit_cron_version") {
            if (
                a.fitbit_cron_version?.last_updated &&
                b.fitbit_cron_version?.last_updated
            ) {
                return (
                    Date.parse(b.fitbit_cron_version?.last_updated) -
                    Date.parse(a.fitbit_cron_version?.last_updated)
                );
            }
            if (b.fitbit_cron_version?.last_updated) {
                return 1;
            }
            if (a.fitbit_cron_version?.last_updated) {
                return -1;
            }
            return 0;
        }
        if (key === "steps_last_day") {
            if (a.steps_last_day && b.steps_last_day) {
                return b.steps_last_day - a.steps_last_day;
            }
            if (b.steps_last_day) {
                return 1;
            }
            if (a.steps_last_day) {
                return -1;
            }
            return 0;
        }
        if (key === "sleep_duration_last_night") {
            if (a.sleep_duration_last_night && b.sleep_duration_last_night) {
                return (
                    b.sleep_duration_last_night - a.sleep_duration_last_night
                );
            }
            if (b.sleep_duration_last_night) {
                return 1;
            }
            if (a.sleep_duration_last_night) {
                return -1;
            }
            return 0;
        }
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        return 0;
    };

    const filteredPatients = patients.filter((patient) =>
        patient.name.toUpperCase().includes(search.toUpperCase())
    );
    const sortedPatients = filteredPatients.sort((a, b) =>
        compare(a, b, sortingConfig.sorting)
    );
    if (!sortingConfig.sortingOrder) {
        sortedPatients.reverse();
    }
    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const goToPatientPage = (userName: string) => {
        history.push(`/${userName}`);
    };
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(errors.length !== 0);
    }, [errors]);

    useEffect(() => {
        getPatientList();
        setPage(0);
        clearPatientErrors("all");
    }, [currentOrganisations, getPatientList]);

    return (
        <>
            <ErrorList
                type="api.daily"
                errors={errors}
                open={open}
                clearPatientErrors={clearPatientErrors}
            />

            <Paper className={classes.searchContainer}>
                <Search className={classes.headerIcon} />
                <InputBase
                    style={{
                        top: "-10px",
                    }}
                    placeholder="Zoek patient op naam..."
                    value={search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearch(e.target.value)
                    }
                />
            </Paper>
            <Paper>
                <TableContainer>
                    <Table>
                        <PatientTableHeader changeSorting={changeSorting} />
                        <PatientTableBody
                            organisations={currentOrganisations}
                            sortedPatients={sortedPatients}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            goToPatientPage={goToPatientPage}
                            loadingData={loadingData}
                            cronDailyFitbitDataByPatientName={
                                cronDailyFitbitDataByPatientName
                            }
                            cronDailyNotificationsByPatientName={
                                cronDailyNotificationsByPatientName
                            }
                        />
                        <PatientTableFooter
                            sortedPatients={sortedPatients}
                            handlePageChange={handlePageChange}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    patients: state.patient.patients,
    currentOrganisations: state.user.currentOrganisations,
    loadingData: state.patient.loadingPatients,
    errors: state.patient.errors,
});
const mapDispatchToProps = {
    getPatientList,
    cronDailyFitbitDataByPatientName,
    cronDailyNotificationsByPatientName,
    clearPatientErrors,
};
const ConnectedPatientTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientTable);

export default ConnectedPatientTable;
