import { Decoder } from "decoders/types";
import APIResult from "./apiresult";
import jsonCheck from "./jsoncheck";

const throwOrReturn = <T>(result: APIResult<T>, decoder?: Decoder<T>): T => {
    if (result.error) {
        // console.error(result.error)
        throw new Error(result.error.response?.data?.error);
    }
    // We can assume that data is valid (type T) if no error was found
    const data = result.data as T;
    // Below is experimental and subject to change
    if (decoder) {
        jsonCheck(data, decoder);
    }
    return data;
};

export default throwOrReturn;
