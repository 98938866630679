import React from "react";
import {
    Box,
    Button,
    CircularProgress,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { RoundedButtonProps } from "./RoundedButton.types";
import styles, {
    ButtonColorCatalogue,
    MarginCatalogue,
} from "./RoundedButton.styles";

const LoadingIconIconOnly: React.FC<Record<string, never>> = () => (
    <CircularProgress
        size={24}
        style={{
            color: "#ffffff",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
        }}
    />
);
const LoadingIconTextButton: React.FC<Record<string, never>> = () => (
    <CircularProgress
        size={24}
        style={{
            color: "#ffffff",
            marginLeft: "-4px",
        }}
    />
);

const RoundedButton: React.FC<RoundedButtonProps> = ({
    Icon,
    EndIcon,
    text,
    onClick,
    color,
    disabled,
    margin = "right",
    loading,
    children,
    tooltipText,
}) => {
    const classes = styles({
        ...ButtonColorCatalogue[color],
        position: MarginCatalogue[margin],
        padding: text ? "6px 16px" : "0",
    });
    if (!text) {
        return (
            <Tooltip
                title={
                    tooltipText ? <Typography>{tooltipText}</Typography> : ""
                }
                placement="top"
            >
                <Box style={{ position: "relative" }}>
                    <Button
                        onClick={onClick}
                        variant="contained"
                        className={classes.root}
                        disabled={disabled}
                        classes={{
                            disabled: classes.disabledButton,
                        }}
                    >
                        {loading ? <LoadingIconIconOnly /> : children}
                    </Button>
                </Box>
            </Tooltip>
        );
    }
    if (Icon && EndIcon) {
        return (
            <Tooltip
                title={
                    tooltipText ? <Typography>{tooltipText}</Typography> : ""
                }
                placement="top"
            >
                <Box style={{ position: "relative" }}>
                    <Button
                        onClick={onClick}
                        variant="contained"
                        startIcon={
                            loading ? <LoadingIconTextButton /> : <Icon />
                        }
                        endIcon={!loading && <EndIcon />}
                        className={classes.root}
                        disabled={disabled}
                        classes={{
                            disabled: classes.disabledButton,
                        }}
                    >
                        {text}
                    </Button>
                </Box>
            </Tooltip>
        );
    }
    if (Icon && !EndIcon) {
        return (
            <Tooltip
                title={
                    tooltipText ? <Typography>{tooltipText}</Typography> : ""
                }
                placement="top"
            >
                <Box style={{ position: "relative" }}>
                    <Button
                        onClick={onClick}
                        variant="contained"
                        startIcon={
                            loading ? <LoadingIconTextButton /> : <Icon />
                        }
                        className={classes.root}
                        disabled={disabled}
                        classes={{
                            disabled: classes.disabledButton,
                        }}
                    >
                        {text}
                    </Button>
                </Box>
            </Tooltip>
        );
    }
    if (!Icon && EndIcon) {
        return (
            <Tooltip
                title={
                    tooltipText ? <Typography>{tooltipText}</Typography> : ""
                }
                placement="top"
            >
                <Box style={{ position: "relative" }}>
                    <Button
                        onClick={onClick}
                        variant="contained"
                        className={classes.root}
                        endIcon={
                            loading ? <LoadingIconTextButton /> : <EndIcon />
                        }
                        disabled={disabled}
                        classes={{
                            disabled: classes.disabledButton,
                        }}
                    >
                        {text}
                    </Button>
                </Box>
            </Tooltip>
        );
    }
    return (
        <Tooltip
            title={tooltipText ? <Typography>{tooltipText}</Typography> : ""}
            placement="top"
        >
            <Box style={{ position: "relative" }}>
                <Button
                    onClick={onClick}
                    variant="contained"
                    className={classes.root}
                    disabled={disabled}
                    classes={{
                        disabled: classes.disabledButton,
                    }}
                >
                    {text}
                </Button>
                {loading && <LoadingIconIconOnly />}
            </Box>
        </Tooltip>
    );
};

export default RoundedButton;
