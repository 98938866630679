/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
// DO NOT EDIT - Generated using Aegis
import {
    Decoder,
    constant,
    optional,
    string,
    boolean,
    number,
    null_,
    undefined_,
    object,
    array,
    either,
    either3,
    either4,
    either5,
    either6,
    either7,
    either8,
    either9,
} from "decoders";

export const configDecoder = object({
    baseURL: string,
    jwt: optional(string),
});
export const loginRequestDecoder = object({
    email: string,
    password: string,
});
export const organisationDataDecoder = object({
    id: number,
    name: string,
    enable_fitbit: boolean,
});
export const loginResponseDataDecoder = object({
    role: string,
    organisations: optional(array(organisationDataDecoder)),
    organisation: optional(organisationDataDecoder),
    jwt: string,
});
export const getPatientsRequestDecoder = object({
    organisation_id: number,
});
export const fitbitCronVersionDecoder = object({
    last_updated: either(string, null_),
    completed_data_update: either(boolean, null_),
    completed_notifications_update: either(boolean, null_),
});
export const patientOverviewFullDataDecoder = object({
    id: number,
    patient_uuid: string,
    user_name: string,
    created_at: string,
    archived_on: either(string, null_),
    sleep_duration_last_night: either(number, null_),
    steps_last_day: either(number, null_),
    flags_last_night: either(array(string), null_),
    body_weight: either(number, null_),
    fitbit_cron_version: either(fitbitCronVersionDecoder, null_),
    last_weigh_in_date: either(string, null_),
    sleep_phase_counter: either(number, null_),
});
export const getPatientsResponseDataDecoder = either(
    array(patientOverviewFullDataDecoder),
    null_
);
export const changePasswordRequestDecoder = object({
    id: number,
    password: string,
});
export const changeNameRequestDecoder = object({
    id: number,
    name: string,
});
export const changePasswordResponseDataDecoder = null_;
export const archivePatientRequestDecoder = object({
    user_name: string,
});
export const archivePatientResponseDataDecoder = null_;
export const deletePatientRequestDecoder = object({
    user_name: string,
});
export const deletePatientResponseDataDecoder = null_;
export const createNewPatientRequestDecoder = object({
    user_name: string,
    password: string,
    organisation_id: number,
});
export const createNewPatientResponseDataDecoder = null_;
export const getDailyAveragesRequestDecoder = object({
    patient_name: string,
    start_date: string,
    end_date: string,
});
export const dailyAverageDataDecoder = object({
    total_activity_fitbit: optional(number),
    heart_rate_nonin_average: optional(number),
    spo2_average_average: optional(number),
    spo2_minimum_average: optional(number),
    pai_average: optional(number),
    hrv_average: optional(number),
    resting_heart_rate: optional(number),
    total_sleeptime_fitbit: optional(number),
    sleep_efficiency_fitbit: optional(number),
    total_deep_sleep_fitbit: optional(number),
    total_rem_sleep_fitbit: optional(number),
    total_wake_sleep_fitbit: optional(number),
    total_light_sleep_fitbit: optional(number),
    weight: number,
    body_fat: number,
    breathing_rate: number,
    temperature_difference: optional(number),
    flags: either(array(string), null_),
});
export const dailyAverageDecoder = object({
    date: string,
    data: dailyAverageDataDecoder,
});
export const valueAndTimeDecoder = object({
    date: string,
    value: number,
});
export const detailedNightDataDecoder = object({
    heart_rate_nonin_average: optional(number),
    spo2_average_average: optional(number),
    spo2_minimum_minimum: optional(number),
    heart_rate_nonin_values: array(valueAndTimeDecoder),
    spo2_average_values: array(valueAndTimeDecoder),
    spo2_minimum_values: array(valueAndTimeDecoder),
    pai_values: array(valueAndTimeDecoder),
    hrv_values: array(valueAndTimeDecoder),
    heart_rate_fitbit_average: optional(either(number, null_)),
    resting_heart_rate: optional(either(number, null_)),
    total_sleeptime_fitbit: optional(either(number, null_)),
    total_deep_sleep_fitbit: optional(either(number, null_)),
    total_rem_sleep_fitbit: optional(either(number, null_)),
    total_wake_sleep_fitbit: optional(either(number, null_)),
    total_light_sleep_fitbit: optional(either(number, null_)),
    sleep_efficiency_fitbit: optional(either(number, null_)),
    total_steps_fitbit: optional(either(number, null_)),
    sleep_stage_values: optional(either(array(valueAndTimeDecoder), null_)),
    heart_rate_fitbit_values: optional(
        either(array(valueAndTimeDecoder), null_)
    ),
    activity_fitbit_values: optional(either(array(valueAndTimeDecoder), null_)),
    cumulative_activity_fitbit_values: optional(
        either(array(valueAndTimeDecoder), null_)
    ),
});
export const detailedNightDecoder = object({
    date: string,
    data: detailedNightDataDecoder,
});
export const realTimeNoninDataDecoder = object({
    heart_rate_nonin_values: array(valueAndTimeDecoder),
    spo2_values: array(valueAndTimeDecoder),
    pai_values: array(valueAndTimeDecoder),
    hrv_values: array(valueAndTimeDecoder),
});
export const getRealTimeNoninDataResponseDecoder = either(
    realTimeNoninDataDecoder,
    null_
);
export const getRealTimeNoninDataRequestDecoder = object({
    patient_name: string,
    moment_date: string,
    timeWindow: number,
});
export const getDailyAveragesResponseDataDecoder = either(
    array(dailyAverageDecoder),
    null_
);
export const getDetailedNightRequestDecoder = object({
    patient_name: string,
    night_date: string,
});
export const getDetailedNightResponseDataDecoder = either(
    detailedNightDecoder,
    null_
);
export const getSettingKeysResponseDecoder = array(string);
export const patientSettingDecoder = object({
    setting_key: string,
    value: number,
});
export const patientSettingsResponseDecoder = either(
    array(patientSettingDecoder),
    null_
);
export const changePatientSettingRequestDecoder = object({
    user_name: string,
    setting_key: string,
    setting_value: number,
});
export const deletePatientSettingRequestDecoder = object({
    user_name: string,
    setting_key: string,
});
export const cronRefreshRequestDecoder = object({
    scheduled: boolean,
});
export const cronRefreshResponseDecoder = null_;
export const cronSettingsUpdateRequestDecoder = object({
    user_name: string,
});
export const cronSettingsUpdateResponseDecoder = null_;
export const emptyResponseDecoder = null_;
export const cronHistoryNotificationsByPatientNameResponseDecoder =
    emptyResponseDecoder;
export const cronDailyNotificationsByPatientNameResponseDecoder =
    emptyResponseDecoder;
export const cronHistoryFitbitDataByPatientNameResponseDecoder =
    emptyResponseDecoder;
export const cronDailyFitbitDataByPatientNameResponseDecoder =
    emptyResponseDecoder;
export const getManageableUsersRequestDecoder = object({
    organisation_id: number,
});
export const manageableUserFullDataDecoder = object({
    ID: number,
    UUID: string,
    UserName: string,
    Email: string,
    role: string,
    CreatedAt: string,
    LastActive: either(string, null_),
    organisation_id: either(number, null_),
});
export const getManageableUsersResponseDecoder = either(
    array(manageableUserFullDataDecoder),
    null_
);
export const deleteUserRequestDecoder = object({
    email: string,
});
export const deleteUserResponseDecoder = null_;
export const createUserRequestDecoder = object({
    user_name: string,
    email: string,
    password: string,
    role: string,
    organisation_id: number,
});
export const createUserResponseDecoder = null_;
export const createOrganisationRequestDecoder = object({
    name: string,
});
export const createOrganisationResponseDecoder = null_;
export const getOrganisationsResponseDecoder = array(organisationDataDecoder);
export const archiveOrganisationRequestDecoder = object({
    organisation_id: number,
});
export const archiveOrganisationResponseDecoder = null_;
export const renameOrganisationRequestDecoder = object({
    organisation_id: number,
    organisation_name: string,
});
export const renameOrganisationResponseDecoder = null_;
export const resetPasswordCompleteRequestDecoder = object({
    token: string,
    password: string,
});
export const resetPasswordCompleteResponseDecoder = null_;
export const resetPasswordStartRequestDecoder = object({
    email: string,
});
export const resetPasswordStartResponseDecoder = null_;
export const cronSettingsResponseDecoder = null_;
export const removeWeightAlarmRequestDecoder = object({
    user_name: string,
});
export const removeWeightAlarmResponseDecoder = null_;
