import {
    DailyAverage,
    DailyAverageData,
    DailyAverageGraphData,
} from "types/patientdata";

import { Filters } from "components/ValuesFilterFormControl/ValuesFilterFormControl.types";
import { dataColors } from "components/ValuesFilterFormControl/ValuesFilterFormControl.styles";
import { dateStringToLocaleDateString } from "./dateToString";
import { minutesToHourMinuteString } from "./time";

export const getDataLabels = (dailyAverages: DailyAverage[]) =>
    dailyAverages.map((dailyAverage) =>
        dateStringToLocaleDateString(dailyAverage.date)
    );

interface YAxesLabel {
    position: "left" | "right";
    id: string;
    offset: boolean;
    display: boolean;
    ticks: {
        precision: number;
        fontColor: string;
        fontSize: number;
        callback?: any;
        min?: number;
        max?: number;
    };
    scaleLabel?: {
        display?: boolean;
        labelString: string;
        fontSize: number;
    };
}

const getYaxesLabel = (
    position: "right" | "left",
    callbackOutput: (any) => string,
    label: string,
    display: boolean,
    axis: number,
    min?: number,
    max?: number
): YAxesLabel => ({
    position,
    id: `y-axis-${axis}`,
    offset: true,
    display,
    ticks: {
        precision: 0,
        fontColor: "#044272",
        fontSize: 16,
        callback(value) {
            return callbackOutput(value);
        },
        min,
        max,
    },
    scaleLabel: {
        display: true,
        labelString: label,
        fontSize: 14,
    },
});
export const getYAxesLabels = (
    dailyAverages: DailyAverage[],
    filters: Filters
): YAxesLabel[] => {
    const tempFilters = {
        average: {
            heart_rate_nonin_average: false,
            spo2_average_average: false,
            spo2_minimum_average: false,
            pai_average: false,
            hrv_average: false,
            resting_heart_rate: false,
            sleep_efficiency_fitbit: false,
            total_sleeptime_fitbit: false,
            temperature_difference: false,
            weight: false,
            total_activity_fitbit: false,
            breathing_rate: false,
        },
        detailed: {},
    };
    dailyAverages.forEach((dailyAverage) => {
        const { data } = dailyAverage;
        for (const prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                if (data[prop] !== 0) {
                    tempFilters.average[prop] = true;
                }
            }
        }
    });
    const result: YAxesLabel[] = [];
    result.push(
        getYaxesLabel(
            "left",
            (value) => `${value}%`,
            "SpO2",
            !!(
                (tempFilters.average.spo2_average_average &&
                    filters.average.spo2_average_average) ||
                (tempFilters.average.spo2_minimum_average &&
                    filters.average.spo2_minimum_average)
            ),
            0,
            -66,
            100
        )
    );

    result.push(
        getYaxesLabel("left", (value) => `${value}/100`, "PAI", false, 1)
    );
    result.push(
        getYaxesLabel(
            "left",
            (value) => `${value}ms`,
            "Heart Rate Variability",
            false,
            2,
            -100,
            150
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}bpm`,
            "Heart Rate",
            false,
            3,
            -100,
            150
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => minutesToHourMinuteString(value, false),
            "Totale slaap tijd",
            !!(
                tempFilters.average.total_sleeptime_fitbit &&
                filters.average.total_sleeptime_fitbit
            ),
            4,
            -510,
            750
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}%`,
            "Sleep Efficiency",
            false,
            5
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}°C`,
            "Temp verschil",
            true,
            7,
            -2,
            3
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}kg`,
            "Gewicht",
            false,
            5,
            -100,
            150
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}%`,
            "Vet",
            false,
            8,
            -100,
            150
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}`,
            "Totale stappen",
            false,
            8,
            -20400,
            30000
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value} brpm`,
            "Ademhaling",
            false,
            9,
            -100,
            150
        )
    );
    return result;
};

export const getAverageGraphYLabels = (
    dailyAverageGraphData: DailyAverageGraphData,
    filters: Filters
): YAxesLabel[] => {
    const tempFilters = {
        average: {
            heart_rate_nonin_average: false,
            spo2_average_average: false,
            spo2_minimum_average: false,
            pai_average: false,
            hrv_average: false,
            resting_heart_rate: false,
            sleep_efficiency_fitbit: false,
            total_sleeptime_fitbit: false,
            temperature_difference: false,
            weight: false,
            total_activity_fitbit: false,
            breathing_rate: false,
        },
        detailed: {},
    };
    for (const prop in dailyAverageGraphData) {
        if (Object.prototype.hasOwnProperty.call(dailyAverageGraphData, prop)) {
            dailyAverageGraphData[prop].data.forEach((point) => {
                if (point.y !== 0) {
                    tempFilters.average[prop] = true;
                }
            });
        }
    }
    const result: YAxesLabel[] = [];
    result.push(
        getYaxesLabel(
            "left",
            (value) => `${value}%`,
            "SpO2",
            !!(
                (tempFilters.average.spo2_average_average &&
                    filters.average.spo2_average_average) ||
                (tempFilters.average.spo2_minimum_average &&
                    filters.average.spo2_minimum_average)
            ),
            2
        )
    );

    result.push(
        getYaxesLabel("left", (value) => `${value}/100`, "PAI", false, 4)
    );
    result.push(
        getYaxesLabel(
            "left",
            (value) => `${value}ms`,
            "Heart Rate Variability",
            false,
            3
        )
    );
    result.push(
        getYaxesLabel("right", (value) => `${value}bpm`, "Heart Rate", true, 1)
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => minutesToHourMinuteString(value, false),
            "Totale slaap tijd",
            !!(
                tempFilters.average.total_sleeptime_fitbit &&
                filters.average.total_sleeptime_fitbit
            ),
            5
        )
    );
    result.push(
        getYaxesLabel("right", (value) => `${value}kg`, "Gewicht", false, 6)
    );
    result.push(
        getYaxesLabel("right", (value) => `${value}%`, "Vet", false, 8)
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}`,
            "Totale stappen",
            false,
            9
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}brpm`,
            "Ademhaling",
            false,
            9
        )
    );
    result.push(
        getYaxesLabel(
            "right",
            (value) => `${value}°C`,
            "Temp verschil",
            false,
            7
        )
    );
    return result;
};

interface DataSetObject {
    type: string;
    label: string;
    backgroundColor: string;
    yAxisID: string;
    data: { x: string; y: number | null | string[] | undefined }[];
}

const getData = (
    dailyAverages: DailyAverage[],
    label: string,
    yAxisNumber: number,
    valueType: keyof DailyAverageData
): DataSetObject => ({
    type: "bar",
    label,
    backgroundColor: dataColors[valueType],
    yAxisID: `y-axis-${yAxisNumber}`,
    data: dailyAverages.map((dailyAverage) => {
        if (
            dailyAverage.data[valueType] !== undefined &&
            valueType !== "flags"
        ) {
            return {
                x: dailyAverage.date,
                y: dailyAverage.data[valueType],
            };
        }
        return {
            x: dailyAverage.date,
            y: undefined,
        };
    }),
});

export const getDataSet = (dailyAverages: DailyAverage[], filters: Filters) => {
    const result: DataSetObject[] = [];
    const tempFilters = {
        average: {
            heart_rate_nonin_average: false,
            spo2_average_average: false,
            spo2_minimum_average: false,
            hrv_average: false,
            resting_heart_rate: false,
            sleep_efficiency_fitbit: false,
            total_sleeptime_fitbit: false,
            temperature_difference: false,
            weight: false,
            breathing_rate: false,
            total_activity_fitbit: false,
        },
        detailed: {},
        realTime: {},
    };
    dailyAverages.forEach((dailyAverage) => {
        const { data } = dailyAverage;
        for (const prop in data) {
            if (Object.prototype.hasOwnProperty.call(data, prop)) {
                if (data[prop] !== 0) {
                    tempFilters.average[prop] = true;
                }
            }
        }
    });
    if (
        filters.average.spo2_average_average &&
        tempFilters.average.spo2_average_average
    )
        result.push(
            getData(dailyAverages, "SpO2 gem", 0, "spo2_average_average")
        );
    if (
        filters.average.spo2_minimum_average &&
        tempFilters.average.spo2_minimum_average
    )
        result.push(
            getData(dailyAverages, "SpO2 min", 0, "spo2_minimum_average")
        );
    if (filters.average.hrv_average && tempFilters.average.hrv_average)
        result.push(getData(dailyAverages, "HRV", 2, "hrv_average"));

    if (filters.average.heart_rate_nonin_average)
        result.push(
            getData(dailyAverages, "HR Nonin", 3, "heart_rate_nonin_average")
        );
    if (
        filters.average.resting_heart_rate &&
        tempFilters.average.resting_heart_rate
    )
        result.push(
            getData(dailyAverages, "HR in rust", 3, "resting_heart_rate")
        );
    if (
        filters.average.total_sleeptime_fitbit &&
        tempFilters.average.total_sleeptime_fitbit
    )
        result.push(
            getData(
                dailyAverages,
                "Totale slaapduur",
                4,
                "total_sleeptime_fitbit"
            )
        );

    if (filters.average.weight && tempFilters.average.weight)
        result.push(getData(dailyAverages, "Gewicht", 5, "weight"));
    if (
        filters.average.temperature_difference &&
        tempFilters.average.temperature_difference
    )
        result.push(
            getData(dailyAverages, "Temp verschil", 7, "temperature_difference")
        );
    if (
        filters.average.total_activity_fitbit &&
        tempFilters.average.total_activity_fitbit
    )
        result.push(
            getData(dailyAverages, "Totale stappen", 8, "total_activity_fitbit")
        );
    if (filters.average.breathing_rate && tempFilters.average.breathing_rate)
        result.push(getData(dailyAverages, "Ademhaling", 9, "breathing_rate"));
    return result;
};
